import React, { Fragment, useState, useEffect } from 'react'
import { Briefcase, CreditCard } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from '../common/breadcrumbv2'
import Switch from '../common/switch'
import FormDatosContacto from './empresa_form';
import FormDatosFiscales from './empresa_df_form';
import DatosFiscales from './empresa_df';
import Tools from '../../js/tools'
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [selected, setSelected] = useState('dc')
    const [empresa, setEmpresa] = useState({})
    const secciones = {
        "dc": 0,
        "df": 1,
        "df-form": 1,
        "df-form-editar": 1
    }

    const onSelectedDf = () => {
        navigate(`/empresa/${params.empresa}`)
        setSelected('df')
    }

    const onSelectedDc = () => {
        navigate(`/empresa/${params.empresa}`)
        setSelected('dc')
    }

    useEffect(() => {
        let isSubscribed = true

        Tools.toolFetch({
            ruta: 'rt_empresa_detalle',
            method: 'POST',
            body: { empresa: params.empresa },
        }, (response) => {
            let datos = response.datos
            if(isSubscribed){
                setEmpresa(datos)
            }
        })
        
        if(params.aliasDf){
            return setSelected('df-form-editar')
        }

        if(params.empresa && window.location.pathname.includes('datos-fiscales')){
            return setSelected('df-form')
        }


        return () => isSubscribed = false
    }, [])

    return (
        <Fragment>
            {/* <LoaderSeccion status={form}/> */}
            <LoaderSeccion status={empresa}/>
            <Breadcrumb icon={Briefcase}  title={empresa.NOMBRE_EM || 'Empresa'} parent={['empresa']} />
            <div className="row theme-tab">
                <Tabs 
                    className="col-sm-12" 
                    onSelect={() => null} 
                    selectedIndex={secciones[selected]}
                >
                    <TabList className="tabs tab-title">
                        <Tab><div onClick={onSelectedDc}><Briefcase /> DATOS DE CONTACTO</div></Tab>
                        {/* <Tab><div onClick={onSelectedDf}><CreditCard /> DATOS FISCALES</div></Tab> */}
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <FormDatosContacto esEditar={true}/>
                        </TabPanel>
                        <TabPanel selected={true}>
                            <Switch selected={selected}>
                                <DatosFiscales value={'df'} setSelected={setSelected} />
                                <FormDatosFiscales value={'df-form'} setSelected={setSelected}/>
                                <FormDatosFiscales value={'df-form-editar'} setSelected={setSelected} esEditar={true}/>
                            </Switch>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </Fragment>)
}

export default Default;