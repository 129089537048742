
import React,{ useState} from "react"

const Default = (props) => {
    const [value, setValue] = useState('')
    let propsSelect = {...props}
    delete propsSelect['tipoValue']
    const onChange = (e) => {
        let value = e.target.value
        let item = props.datos.find((s) => s.clave == value)
        item = item !== undefined && item !== null?item:{clave:''}
        setValue(item.clave)
        props.onChange({item, e})
    }

    const handleMouseDown = (e) => {
        // Evitar que se despliegue el dropdown si `disableDropdown` es true
        if (props.disableDropdown) {
            e.preventDefault()
        }
    }

    return (
    <select
        {...propsSelect}
        className="form-control form-control-md"
        onChange={props.onChange?onChange:null}
        value={props.tipoValue === 1? (value || props.value): (props.value? (props.value): value)}
        onMouseDown={handleMouseDown}
    >
        <option value="">{props.placeholder?props.placeholder: 'Seleccionar opción'}</option>
        {props.datos?
            props.datos.map((v, i) => (
                <option key={v.clave+'-'+i} value={v.clave}>{v.label}</option>
            ))
        : ''}
    </select>)
}

export default Default