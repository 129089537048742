import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import Select from '../common/select-basic'
import { Truck } from 'react-feather';
import { useParams } from 'react-router-dom';
import notie from 'notie';
import Tools from '../../js/tools';
//FORMULARIOS

import Breadcrumb from '../common/breadcrumbv2';    
import LoaderSeccion from '../../components/common/loader_secciones';

const Default = (props) => {
    const [catalogos, setCatalogos] = useState({empresas: [], estados: [], ciudades: [], sucursal: {}});
    const [datosSucursal, setDatosSucursal] = useState();
    const params = useParams();
    
    const enviarDatosFormulario = () => {
        let tipo = document.querySelector('#select_tipo_suc').text
        let datos = Tools.getForm('form_sucursal', {ignore: tipo === 'Física' ? [ 'input_num_int', 'input_referencias', 'input_buscar' ] : [ 'input_num_int', 'input_referencias', 'input_buscar', 'input_calle', 'input_num_ext', 'input_num_int', 'input_colonia', 'input_cp', 'select_estado', 'select_ciudad', 'input_referencias', 'input_longitud', 'input_latitud'] });

        datos.data.sucursal = params.sucursal;
        
        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.stringIsEmpty} es requerido`, type: 3, time: 4});
            return;
        }
        if (!Tools.validMail(datos.data.input_correo)) {
            document.querySelector(`.input_correo`).style['display'] = 'block'
            notie.alert({text: `Ingresa un correo válido.`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_telefono.length < 10){
            document.querySelector(`.input_telefono`).style['display'] = 'block'
            notie.alert({text: `El campo Teléfono debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_celular.length < 10){
            document.querySelector(`.input_celular`).style['display'] = 'block'
            notie.alert({text: `El campo Celular debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }
        if( tipo === 'Física' & datos.data.input_cp.length < 5){
            document.querySelector(`.input_cp`).style['display'] = 'block'
            notie.alert({text: `El campo Código postal debe ser de 5 caracteres`, type: 3, time: 4});
            return;
        }
        
        fetch('../../rt_sucursales_editar', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then(function(res) { return res.json(); })
        .then(function(res) {
            console.log(res);
            if(res.estatus === 'OK'){
                if(res.filas > 0){
                    notie.alert({text: 'Actualizado con éxito', type: 1, time: 4});
                }else{
                    notie.alert({text: 'No se encontró la sucursal', type: 2, time: 5});
                }
            }else{
                throw Error(res.estatus);
            }
        }).catch(function(err){
            console.log(err);
            notie.alert({text: 'No se pudo agregar, inténtelo más tarde', type: 3, time: 5});
        })
    }
    
    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    const changeState = (e) => {
        console.log(e);
        let clave = (e);
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: {clave},
        }, (response) => {
            setCatalogos({...catalogos, ciudades: response.datos})
        })
    }

    function selectedType(e){
        let value = e.e.target.value
        if(value === "1"){
            document.getElementById('form_address').removeAttribute('hidden')
        }else{
            document.getElementById('form_address').setAttribute('hidden', 'hidden')
        }
    }

    function populateAddressFields(addressComponents) {
        const componentForm = {
            administrative_area_level_1: `select_estado`,
            street_number: `input_num_ext`,
            route: `input_calle`,
            sublocality: `input_colonia`,
            postal_code: `input_cp`,
            locality: `select_ciudad`,
        };
        for (let component in componentForm) {
            document.getElementById(componentForm[component]).value = '';
        }
        document.getElementById('input_referencias').value = '';
        let cityComponent = null;
        addressComponents.forEach(component => {
            component.types.forEach(type => {
                if (componentForm[type]) {
                    if (type === 'locality') {
                        cityComponent = component;
                    } else if (type === 'administrative_area_level_1') {
                        for (let i = 0; i < document.getElementById(componentForm[type]).options.length; i++) {
                            if (document.getElementById(componentForm[type]).options[i].text === component.long_name) {
                                document.getElementById(componentForm[type]).selectedIndex = i;
                                document.getElementById(componentForm[type]).dispatchEvent(new Event('change', { bubbles: true, cancelable: true, }));
                                break;
                            }
                        }
                    } else {
                        document.getElementById(componentForm[type]).value = component.long_name;
                    }
                }
            });
        });
    
        if (cityComponent) {
            setTimeout(() => {
                for (let i = 0; i < document.getElementById(componentForm['locality']).options.length; i++) {
                    if (document.getElementById(componentForm['locality']).options[i].text === cityComponent.long_name) {
                        document.getElementById(componentForm['locality']).selectedIndex = i;
                        document.getElementById(componentForm['locality']).dispatchEvent(new Event('change'));
                        break;
                    }
                }
            }, 1500);
        }
    }
    
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            return new Promise((resolve, reject) => {
                if (window.google) {
                    resolve(window.google);
                } else {
                    const script = document.createElement('script');
                    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAsuT7ZRnpRj9ToHba9X__jOUQ2kYNUMGw&libraries=places";
                    script.async = true;
                    script.defer = true;
                    script.onload = () => resolve(window.google);
                    script.onerror = reject;
                    document.head.appendChild(script);
                }
            });
        };

        async function initMap() {
            const google = await loadGoogleMapsScript();
            const [{ Map }] = await Promise.all([
                google.maps.importLibrary("places")
            ]);
            const input = document.getElementById(`input_buscar`)
            const autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener('place_changed', async () => {
                const place = autocomplete.getPlace();
                populateAddressFields(place?.address_components);
                if (!place.geometry) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }
                const lat = place.geometry.location.lat();
                const lon = place.geometry.location.lng();
                document.getElementById(`input_latitud`).value = lat
                document.getElementById(`input_longitud`).value = lon
            });
        }

        initMap();
    }, []);

    useEffect(() => {
        fetch('/rt_sucursales_detalle', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {'Content-Type': 'application/json'}
        }).then(function(res) { return res.json(); })
        .then(function(res) {
            console.log('res', res);
            if(res.estatus === 'OK'){
                setCatalogos(
                    (c) =>  ({
                        ...c,
                        empresas: res.datos.empresas,
                        estados: res.datos.estados,
                        ciudades: res.datos.ciudades,
                        tipo_suc: res.datos.tipo_suc
                    })
                );
                setDatosSucursal((s) => ({...res.datos.sucursal}));
            }
            else
                throw Error(res.estatus);
        }).catch(function(err){
            notie.alert({text: 'No se pudo obtener los catálgos, inténtelo más tarde', type: 4, time: 5});
        })
    }, [])

    useEffect(() => {
        let value = datosSucursal?.ID_TIPO_SUC
        if(value === 1){
            document.getElementById('form_address').removeAttribute('hidden')
        }else{
            document.getElementById('form_address').setAttribute('hidden', 'hidden')
        }
    }, [datosSucursal])

    return (
        <Fragment>
            <LoaderSeccion status={datosSucursal} />
            <Breadcrumb icon={Truck} title={'Editar sucursal'} parent={['sucursales', params.sucursal]}/>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_sucursal' className="theme-form">
                            <div className="form-group row">
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="select_tipo_suc">Tipo de sucursal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="tipo_suc"
                                            placeholder="Seleccione un tipo de sucursal"
                                            id="select_tipo_suc"
                                            name="select_tipo_suc"
                                            tipoValue={1}
                                            value={datosSucursal?.ID_TIPO_SUC || ''}
                                            datos={catalogos.tipo_suc}
                                            onChange={(e) => selectedType(e)}
                                        />
                                        <span className="text-danger txtVacios select_tipo_suc" style={{ display: 'none' }}><strong>Falta completar (Nombre de la sucursal)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_nombre">Nombre<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_nombre' id='input_nombre' type="text" data-label="" placeholder='Nombre de la sucursal' defaultValue={datosSucursal?.NOMBRE || ''}/>
                                        <span className="text-danger txtVacios input_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_correo">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_correo' id='input_correo' type="email" placeholder="Correo de contacto" defaultValue={datosSucursal?.CORREO_CONTACTO || ''} maxLength={80}/>
                                        <span className="text-danger txtVacios input_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_telefono">Teléfono<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_telefono' id='input_telefono' type="text" placeholder="Teléfono de contacto" defaultValue={datosSucursal?.TELEFONO || ''}  onChange={validarNumeros} maxLength={10}/>
                                        <span className="text-danger txtVacios input_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_celular">Celular de contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_celular' id='input_celular' type="text" placeholder="Celular (Whatsapp)" defaultValue={datosSucursal?.CELULAR || ''}  onChange={validarNumeros} maxLength={10}/>
                                        <span className="text-danger txtVacios input_celular" style={{ display: 'none' }}><strong>Falta completar (Celular (Whatsapp))</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row" id='form_address'>
                                <div className='col-md-12 mb-4'>
                                    <label className="col-form-label" htmlFor="input_buscar">Buscar dirección</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_buscar' id='input_buscar' type="text" data-label="Buscar dirección" placeholder="Ingresa una ubicación"/>
                                    </div>
                                    <span className="text-danger txtVacios input_buscar" style={{ display: 'none' }}><strong>Falta completar (Buscar dirección)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_calle">Calle<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_calle' id='input_calle' type="text" data-label="" placeholder='Calle' defaultValue={datosSucursal?.CALLE || ''} maxLength={50}/>
                                        <span className="text-danger txtVacios input_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_ext">Número exterior<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_ext' id='input_num_ext' type="text" data-label="" placeholder='Numero exterior' defaultValue={datosSucursal?.NUM_EXT || ''}/>
                                        <span className="text-danger txtVacios input_num_ext" style={{ display: 'none' }}><strong>Falta completar (Número exterior)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_int">Número interior</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_int' id='input_num_int' type="text" data-label="" placeholder='Numero interior' defaultValue={datosSucursal?.NUM_INT || ''}/>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_colonia">Colonia<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_colonia' id='input_colonia' type="text" data-label="" placeholder='Colonia' defaultValue={datosSucursal?.COLONIA || ''}/>
                                        <span className="text-danger txtVacios input_colonia" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_cp">Código postal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_cp' id='input_cp' type="text" data-label="" placeholder='Código postal' defaultValue={datosSucursal?.CP || ''} maxLength={5} onInput={(e) => Tools.formatoNumber(e)}/>
                                        <span className="text-danger txtVacios input_cp" style={{ display: 'none' }}><strong>Falta completar (Código postal)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_estado">Estado<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="estado"
                                            placeholder="Seleccione un Estado"
                                            id="select_estado"
                                            name="select_estado"
                                            value={datosSucursal ? datosSucursal.ID_ESTADO : ''}
                                            tipoValue={1}
                                            datos={catalogos.estados}
                                            onChange={(e) => {changeState(e.e.target.value)}}
                                        />
                                        <span className="text-danger txtVacios select_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_ciudad">Ciudad<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="ciudad"
                                            placeholder="Seleccione una Ciudad"
                                            id="select_ciudad"
                                            name="select_ciudad"
                                            value={datosSucursal ? datosSucursal.ID_CIUDAD : ''}
                                            tipoValue={1}
                                            datos={catalogos.ciudades}
                                            onChange={() => null}
                                        />
                                        <span className="text-danger txtVacios select_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_referencias">Referencias de dirección</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_referencias' id='input_referencias' type="text" data-label="" placeholder='Referencias' defaultValue={datosSucursal?.REFERENCIA || ''} maxLength={100}/>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_latitud">Latitud<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_latitud' id='input_latitud' type="text" data-label="" placeholder='Latitud' defaultValue={datosSucursal?.LATITUD || ''} maxLength={40} onInput={(e) => Tools.formatoCoordenadas(e)}/>
                                        <span className="text-danger txtVacios input_latitud" style={{ display: 'none' }}><strong>Falta completar (Latitud)</strong></span>
                                    </div>
                                </div>                                
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_longitud">Longitud<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_longitud' id='input_longitud' type="text" data-label="" placeholder='Longitud' defaultValue={datosSucursal?.LONGITUD || ''} maxLength={40} onInput={(e) => Tools.formatoCoordenadas(e)}/>
                                        <span className="text-danger txtVacios input_latitud" style={{ display: 'none' }}><strong>Falta completar (Longitud)</strong></span>
                                    </div>
                                </div>
                            </div>
                            <button className='btn btn-purple' type='button' onClick={enviarDatosFormulario}>Guardar sucursal</button>
                        </form>
                    </div>
                </div>
            </div>

        </Fragment>)
}



export default Default;