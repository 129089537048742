import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../../components/common/breadcrumb';
import { Send } from 'react-feather';
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";

export const groupArrayByKey = (xs, key, param) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
        return rv;
    }, {});
}

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [envio, setEnvio] = useState({})
    const [solicitud, setSolicitud] = useState({})
    const [partidas, setPartidas] = useState([])
    const navigate = useNavigate();
    const params = useParams();

    const abrirAccordion = () => {
        let accordion_items = document.querySelectorAll('.accordion-item>.title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    const verDatelle = (e, item) => {
        e.preventDefault()
        console.log(item)
    }

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_envio_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            console.log(datos)
            setEnvio(datos.detalleEnvio)
        })

        abrirAccordion()
    }, [])

    return (
        <div id="divToPrint">
            <Fragment
            >
                <div className="col-sm-12 mb-1 mt-3">
                    <Breadcrumb
                        title={"Rutas"}
                        parent={"Detalle"}
                    />
                </div>

                <div className="col-md-12 d-flex align-items-end justify-content-start mb-3 ml-0 p-0">
                    <button className="btn btn-regresar me-1" type="button"
                        onClick={(e) => {
                            navigate('/rutas')
                        }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    <button type="button" className="btn btn-purple ms-1">
                        <i className="fa fa-download"></i> Descargar
                    </button>
                </div>

                <div className='container card pt-5'>
                    <div className="row m-auto w-100">
                        <div className="col-md-12 p-0">
                            <form className="theme-form">
                                <div className="row m-auto">
                                    <div className="col-md-6">
                                        <div className="info-container">
                                            <h5 className=" font-weight-bold mb-0">
                                                Número de órdenes:
                                            </h5>
                                            <h5 style={{color: '#521F8C', fontWeight: 'bolder', fontSize: '40px'}}>
                                                23
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="info-container">
                                            <h5 className=" font-weight-bold mb-0">
                                                Total de direcciones:
                                            </h5>
                                            <h5 style={{color: '#521F8C', fontWeight: 'bolder', fontSize: '40px'}}>
                                                32
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row m-auto w-100 mt-5">
                        <div className="col-md-12 p-0">
                            <form className="theme-form">
                                <div className="row m-auto">
                                    <div className='col-12'>
                                        <h5 className="font-weight-bold mb-0" style={{color: '#521F8C', fontWeight: 'bolder'}}>
                                            Paquetes:
                                        </h5>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="info-container">
                                                    <ul style={{fontSize: '17px'}}>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 1</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 2</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 3</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 4</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 5</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 6</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 7</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 8</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 9</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 10</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="info-container">
                                                    <ul style={{fontSize: '17px'}}>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 11</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 12</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 13</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 14</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 15</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 16</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 17</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 18</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 19</li>
                                                        <li><BtnVer
                                                            onClick={() => {
                                                                navigate(`/envios/detalle`)
                                                            }}
                                                        /> Paquete 20</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </Fragment>
        </div>
    )
}

export default Default;