import React, { Fragment, useState, useEffect } from 'react';
import Tools from '../../js/tools'
import Table from '../common/tabla'
import notie from 'notie'
import Select from '../common/select-basic'
import tools from '../../js/tools';
import Filtros from '../common/dropdown_simple'
import { Sliders } from 'react-feather'
import { FilterRemove } from '../common/button-icons'

const Default = ({
    esRefresh, setEsRefresh
}) => {
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const [filtros, setFiltros] = useState({ cp: '' })
    const [catalogos, setCatalogos] = useState({
        ctgEdo: [],
        ctgCd: []
    })
    let timeout = 0

    const limpiarFiltro = () => {
        setFiltros({})
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s, ctgCd: [] }))
    }

    const onSelectEstado = ({ item }) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            document.getElementById('txtCp').value = ''
            setFiltros((s) => ({
                ...s,
                cp: null,
                list_cd: null,
                list_edo: item.clave
            }))
            setEsRefreshLoc((s) => !s)
            setCatalogos((s) => ({
                ...s,
                ctgCd: response.datos
            }))
        })
    }

    const onSelectCd = (datos) => {
        document.getElementById('txtCp').value = ''
        setFiltros((s) => ({ ...s, cp: null, list_cd: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
        
    }

    const buscarCp = (cp) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            setFiltros((s) => ({ cp }))
            setEsRefreshLoc((s) => !s)
        }, 500)
    }

    const onClickSeleccion = (datos, callback) => {
        let checked = Object.values(datos.checked).map((v) => ({ ...v, ASENTAMIENTOS: '' }))

        const ejecutar = () => {
            if (!datos.checkedTotal) {
                if (!checked.length) {
                    return notie.alert({ type: 'warning', text: 'No se han seleccionado C.P.', time: 2 })
                }
            }

            notie.confirm({
                text: `
                Seleccionar grupo al que perteneceran los <strong>Códigos Postales</strong>
                <div class="row d-flex justify-content-center">
                    <div class="col-md-6">
                        <select id="list_gpo_cob" class="form-control form-control-md">
                            <option value="">Seleccionar grupo C.P.</option>
                            ${catalogos.ctgGpoCob.map((v) => `<option value="${v.clave}">${v.label}</option>`)
                    }
                        </select>
                    </div>
                </div>
                `,
                submitText: 'Guardar C.P.',
                cancelText: 'Cancelar',
                cancelCallback: function () {
                },
                submitCallback: function () {
                    let list_gpo_cob = document.getElementById('list_gpo_cob')

                    if (!list_gpo_cob.value) {
                        setTimeout(() => ejecutar(), 2000)
                        return notie.alert({ type: 'warning', text: 'Seleccione un grupo de C.P.', time: 2 })
                    }

                    tools.toolFetch({
                        ruta: 'rt_cobertura_definir',
                        method: 'POST',
                        body: {
                            list_gpo_cob: list_gpo_cob.value,
                            checked,
                            checkedTotal: datos.checkedTotal,
                            cp: filtros.cp,
                            list_cd: filtros.list_cd,
                            list_edo: filtros.list_edo
                        }
                    }, (response) => {
                        notie.alert({ type: 'success', text: 'Información guardada con éxito.', time: 2 })
                        setFiltros((s) => ({}))
                        setEsRefresh((s) => !s)
                        setEsRefreshLoc((s) => !s)
                        callback({ estatus: 1 })
                    })
                }
            })
        }

        ejecutar()
    }

    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_edo',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgEdo: response.datos }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_gpo_cob',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgGpoCob: response.datos.ctgGpoCob }))
        })
    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])

    return (
        <Fragment>
            <div className="card-header p-3 card-codigos">
                <div className='d-flex justify-content-center'>
                    <h4>Sin cobertura</h4>
                </div>
                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end align-items-center">
                        <FilterRemove
                            className={'m-1'}
                            style={{ cursor: 'pointer', fontSize: 14 }}
                            onClick={() => limpiarFiltro()}
                        />
                        <Filtros
                            title={<Fragment><Sliders size={14} /> Filtros</Fragment>}
                            items={[
                                {
                                    element:
                                        <div style={{ width: 300 }}>
                                            <label className="form-label">Filtrar por estado</label>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Estado"
                                                placeholder="Seleccionar Estado"
                                                id="list_edo"
                                                name="list_edo"
                                                tipoValue={1}
                                                value={filtros.list_edo ? filtros.list_edo : ''}
                                                datos={catalogos.ctgEdo}
                                                onChange={onSelectEstado}
                                            />
                                        </div>
                                },
                                {
                                    element:
                                        <div>
                                            <label className="form-label">Filtrar por municipio</label>
                                            <Select
                                                className="form-control form-control-md border border-dark"
                                                data-label="Municipio"
                                                placeholder="Seleccionar Municipio"
                                                id="list_cd"
                                                name="list_cd"
                                                tipoValue={1}
                                                value={filtros.list_cd ? filtros.list_cd : ''}
                                                datos={catalogos.ctgCd}
                                                onChange={onSelectCd}
                                            />
                                        </div>
                                },
                                {
                                    element:
                                        <div style={{ width: 300 }}>
                                            <label htmlFor="buscar">
                                                Filtro por código postal
                                            </label>
                                            <input
                                                id="txtCp"
                                                name="txtCp"
                                                type="text"
                                                className="form-control"
                                                // value={filtros.cp?filtros.cp:''}                                    
                                                onKeyUp={(e) => {
                                                    buscarCp(e.target.value)
                                                }}
                                            />
                                        </div>
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 codigos">
                <Table
                    sinBuscador={true}
                    noRutaDinamica={true}
                    cargando={false}
                    row={{
                        ignoreColumns: [],
                        onClick: (e, row) => {
                            // console.log(row)
                        }
                    }}
                    seleccion={{
                        onClickSeleccion: onClickSeleccion,
                        btnNombre: 'Guardar C.P.',
                        className: "btn btn-purple btn-nuevo",
                    }}
                    headers={[
                        {
                            nombre: 'CÓDIGOS PORTALES', tipo: 'html', texto: 'ASENTAMIENTOS',
                            style: { width: 0 },
                            html: (d) => {
                                let asentamientos = JSON.parse(d.ASENTAMIENTOS ? d.ASENTAMIENTOS : '[]')
                                return <div style={{ width: '100%' }}>
                                    <div className="card-body p-0">
                                        <h4 className="card-title pb-0 mb-0"><strong>{d.CLAVE_CP}</strong></h4>
                                        <strong className='mb-2'><span style={{ fontSize: '1em' }}>{d.ESTADO}, {d.CIUDAD}.</span></strong>
                                        <div className='row'>
                                            {asentamientos.map((v) => {
                                                return <div key={v.ID_AS + v.ASENTAMIENTO_AS} className={`col-sm-${asentamientos.length === 1 ? '12' : '6'}`}>
                                                    <div className="card pb-0 mb-0">
                                                        <h6 className="card-title"><span style={{ color: '#3B1195' }}>{v.ASENTAMIENTO_AS}</span></h6>
                                                        <h6 className="card-subtitle mb-2 text-muted">{v.TIPO_ASENTAMIENTO_AS} | {v.ZONA_AS}.</h6>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                        },
                        {
                            nombre: '', tipo: 'check', texto: 'SELECCIONAR', chkStyle: { width: 20, height: 20 },
                        }
                    ]}
                    totalField={'NR'} /* NR = número de resultados totales */
                    path={'/rt_cobertura_por_definir'}
                    refresh={esRefreshLoc}
                    body={{ ...filtros }}
                    rowsPerPage={20}
                    paginadorTop={true}
                >
                </Table>
            </div>
        </Fragment>
    );
};

export default Default;