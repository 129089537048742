import React, { Fragment, useState, useEffect } from 'react';
import Select from '../../common/select-basic'
import Tools from '../../../js/tools'
import notie from 'notie'
import { Box } from 'react-feather';
import Breadcrumb from '../../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from '../../common/dropzone';
import SelectFetch from '../../common/select-fetch';
import SelectSimple from '../../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../../common/tabla_estatica';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import LoaderSeccion from '../../../components/common/loader_secciones';

const Default = ({ esEditar }) => {
    const [conductor, setConductor] = useState(null)
    const [usuarioTPV, setUsuarioTPV] = useState(null)
    const [datosUsuario, setDatosUsuario] = useState({});
    const [catalogos, setCatalogos] = useState({empresas: [], tipos: [], permisosSecciones: [], sucursales: []});
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate()
    const params = useParams()
    var id_ut
    
    async function guardar(){
        setIsUpdating(true);
        let usuario = document.getElementById('list_usuario').value
        const datos = Tools.getForm('form_usuario', { claveEtiquetas: '.txtVacios',  ignore: !esEditar ? usuario == -1 ? ['input_CELULAR','txt_licencia', 'txt_telefono', 'txt_desc'] : ['input_EMPRESA', 'input_USUARIO', 'input_NOMBRES', 'input_APELLIDOS', 'input_CORREO', 'input_TELEFONO', 'input_CELULAR', 'input_TIPO', 'input_SUC', 'txt_licencia', 'txt_telefono', 'txt_desc'] : ['list_usuario', 'input_EMPRESA', 'input_USUARIO', 'input_NOMBRES', 'input_APELLIDOS', 'input_CORREO', 'input_TELEFONO', 'input_CELULAR', 'input_TIPO', 'input_SUC', 'txt_licencia', 'txt_telefono', 'txt_desc'] })
        if(!esEditar && usuario == -1){
            id_ut = await enviarDatosFormulario(datos)
            console.log('ID recibido:', id_ut)
        }   
        if(usuario != -1){
            id_ut = usuario
        }
        
        if(id_ut != null){
            datos.data.id_ut = id_ut
            console.log('datos formulario', datos);
    
            // return
            Tools.toolFetch({
                ruta: esEditar ? 'rt_drivin_conductor_editar' : 'rt_drivin_conductor_agregar',
                method: 'POST',
                body: {
                    ...datos.data,
                },
            }, (response) => {
                if (response.estatus === 1) {
                     notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `El conductor ha sido agregado con exito`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        
                    })
                    setIsUpdating(false);
                    setTimeout(() => {
                        window.location.href = '/conductores'
                    }, 2000);                
                }
                if (response.estatus === 0) {
                    setIsUpdating(false)
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `No se ha podido agregar el conductor`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
                if (response.estatus === 3) {
                    setIsUpdating(false)
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ya existe un conductor con este correo`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
                setIsUpdating(false);
    
            })
        }
    }

    async function enviarDatosFormulario(datos){

        if (datos.formIsEmpty) {   
            setIsUpdating(false);
            let mensaje = `${datos.formIsEmpty ? datos.stringIsEmpty : ''}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let form_permisos = new FormData(document.getElementById('form_permisos'));
        let permisos_form = [], permiso_split, e;
        for(e of form_permisos.entries()){
            permiso_split = e[0].split('_');
            permisos_form.push({
                id: permiso_split[2]
            });
        };

        datos.data['permisos'] = JSON.stringify(permisos_form);
/*         let body = {}

        Object.keys(datos.data).foreach(key => {
            body[key.replace(/input_/g, '')] = datos.data[key];
        }) */
       try {
            const response = await fetch('../rt_tpv_agregar_usuario_permisos', {
                method: 'POST',
                body: JSON.stringify(datos.data),
                headers: { 'Content-Type': 'application/json' },
            });
            const res = await response.json();
            if (res.estatus === 'OK') {
                if (res.datos.ESTATUS === 'AGREGADO') {
                    notie.alert({ text: 'Usuario creado correctamente', type: 1, time: 5 });
                    console.log('ID generado:', res.datos.ID);
                    return res.datos.ID; // Aquí devolvemos el ID
                } else {
                    setIsUpdating(false);
                    notie.alert({ text: 'El usuario ya se encuentra registrado', type: 2, time: 5 });
                    return null;
                }
            } else {
                setIsUpdating(false);
                throw new Error(res.estatus);
            }
       } catch (error) {
            setIsUpdating(false);
            console.error(error);
            notie.alert({ text: 'No se pudo agregar el usuario, inténtelo más tarde', type: 3, time: 6 });
            return null;
       }
    }

    const actualizar = (id) => {
        setIsUpdating(true);

        const _conductor = Tools.getForm('formconductor', { claveEtiquetas: '.txtVacios', ignore: [ 'txt_telefono', 'txt_licencia', 'txt_desc'] })
        
        if (_conductor.formIsEmpty) {   
            let mensaje = `${_conductor.formIsEmpty ? _conductor.stringIsEmpty : ''}`
             notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            setIsUpdating(false);
            return
        }

        // return
        Tools.toolFetch({
            ruta:  'rt_drivin_conductor_editar' ,
            method: 'POST',
            body: {
                ..._conductor.data,
                id
            },
        }, (response) => {
            console.log(response)
            if (response.estatus === 1) {
                 notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El conductor ha sido actualizado con exito`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    
                })              
            }
            if (response.estatus === 3) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
           
            if (response.estatus == 0) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'No se pudo actualizar la información del conductor. Por favor, inténtelo más tarde.',
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setIsUpdating(false);

        })
    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    function inputValue(){
        document.getElementById('txt_nombre').value = document.getElementById('input_NOMBRES').value
        document.getElementById('txt_apellidos').value = document.getElementById('input_APELLIDOS').value
        document.getElementById('txt_correo').value = document.getElementById('input_CORREO').value
        document.getElementById('txt_telefono').value = document.getElementById('input_TELEFONO').value
    }

    function toogleForm(toogle) {
        if(toogle == -1){
            document.getElementById('formUsuario').classList.remove('d-none')
            Tools.toolFetch({
                ruta: 'rt_tpv_catalogos_agregar_usuario',
                method: 'POST',
                body: {}
            }, (response) => {
                setDatosUsuario((s) => ({...response.datos.usuario}));
                setCatalogos(response.datos);
                document.getElementById('txt_nombre').value = ''
                document.getElementById('txt_apellidos').value = ''
                document.getElementById('txt_correo').value = ''
                document.getElementById('txt_telefono').value = ''
            })
        }else{
            document.getElementById('formUsuario').classList.add('d-none')
            document.getElementById('txt_nombre').value = ''
            document.getElementById('txt_apellidos').value = ''
            document.getElementById('txt_correo').value = ''
            document.getElementById('txt_telefono').value = ''
            Tools.toolFetch({
                ruta: 'rt_tpv_usuarios_catalogos_detalle',
                method: 'POST',
                body: {id: toogle}
            }, (response) => {
                setDatosUsuario((s) => ({...response.datos.usuario}));
                setCatalogos(response.datos);
                document.getElementById('txt_nombre').value = response.datos.usuario.NOMBRES
                document.getElementById('txt_apellidos').value = response.datos.usuario.APELLIDOS
                document.getElementById('txt_correo').value = response.datos.usuario.CORREO
                document.getElementById('txt_telefono').value = response.datos.usuario.TELEFONO
            })
        }
    }

    useEffect(() => {
        let isSubscribed = true

        fetch('/rt_drivin_usuarios_tpv', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({})
        }).then((response) => response.json())
        .then(async (response) => {
            setUsuarioTPV(response.datos.usuarios)
        })
        
        if (esEditar) {
            return Promise.all([
                fetch('/rt_drivin_conductor_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ conductor: params.id })
                }).then((response) => response.json())
                .then(async (response) => {
                    setConductor((s) => ({ ...s, ...response.datos.conductor }))
                })
            ])       
        }
        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            {esEditar?<LoaderSeccion status={conductor}/>:<LoaderSeccion status={usuarioTPV}/>}
            <Breadcrumb icon={Box} title={'Conductores'} parent={['conductores', , esEditar?params.id : 'nuevo']} />
            <div className="container-fluid">
                <div className='row'>
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        navigate('/conductores')
                                    }} ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} CONDUCTOR</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <form id='form_usuario'>
                                    <div className='row'>
                                        {!esEditar ? 
                                            <>
                                                <div className="form-group row">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="validationTooltip02">Selecciona el usuario<span className="text-danger">*</span></label>
                                                        <div className="input-group input-gpack2">
                                                            <div className="input-group-prepend"></div>
                                                            <Select
                                                                className="form-control form-control-md border border-dark"
                                                                data-label="Usuario"
                                                                placeholder="Seleccionar usuario"
                                                                id="list_usuario"
                                                                name="list_usuario"
                                                                tipoValue={1}
                                                                datos={usuarioTPV}
                                                                value={conductor ? conductor.PERFIL_DRV : ''}
                                                                onChange={(datos) => {
                                                                    setConductor({USUARIO_TPV: datos.item.clave})
                                                                    toogleForm(datos.item.clave)
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="text-danger txtVacios list_usuario" style={{ display: 'none' }}><strong>Falta completar (Usuario)</strong></span>
                                                    </div>
                                                    <div className="form-group row d-none" id='formUsuario'>
                                                        <div className="col-md-12 mb-3 mt-3">
                                                            <h6><strong>Agregar nuevo usuario TPV</strong></h6>
                                                            <hr />
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className='col-form-label' htmlFor="input_EMPRESA">Empresa<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <select className='form-select' data-label='Empresa' name='input_EMPRESA' id='input_EMPRESA'>
                                                                    <option hidden value={''}>Seleccione una empresa</option>
                                                                    {
                                                                        catalogos.empresas.map(item => 
                                                                        <option key={`key_option_empresas_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            <span className="text-danger txtVacios input_EMPRESA" style={{ display: 'none' }}><strong>Falta completar (Empresa)</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className="col-form-label" htmlFor="input_USUARIO">Usuario<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <input className="form-control" name='input_USUARIO' id='input_USUARIO' type="text" data-label="Usuario" placeholder='Usuario'/>
                                                            </div>
                                                            <span className="text-danger txtVacios input_USUARIO" style={{ display: 'none' }}><strong>Falta completar (Usuario)</strong></span>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="validationTooltip02">Nombre (s)<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <div className="input-group-prepend"></div>
                                                                <input className="form-control" id="input_NOMBRES" name="input_NOMBRES" type="text" placeholder="Nombre (s)" onInput={inputValue}/>
                                                            </div>
                                                            <span className="text-danger txtVacios input_NOMBRES" style={{ display: 'none' }}><strong>Falta completar (Nombre (s))</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className="col-form-label" htmlFor="input_APELLIDOS">Apellidos<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <input className="form-control" name='input_APELLIDOS' id='input_APELLIDOS' type="text" data-label="apellidos" placeholder='Apellidos' onInput={inputValue}/>
                                                            </div>
                                                            <span className="text-danger txtVacios input_APELLIDOS" style={{ display: 'none' }}><strong>Falta completar (Apellidos)</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className="col-form-label" htmlFor="input_CORREO">Correo<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <input className="form-control" name='input_CORREO' id='input_CORREO' type="text" data-label="correo electrónico" placeholder='Correo electrónico' onInput={inputValue}/>
                                                            </div>
                                                            <span className="text-danger txtVacios input_CORREO" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className="col-form-label" htmlFor="input_TELEFONO">Teléfono<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <input className="form-control" name='input_TELEFONO' id='input_TELEFONO' type="text" data-label="teléfono" placeholder='Teléfono' onInput={inputValue} maxLength={10}/>
                                                            </div>
                                                            <span className="text-danger txtVacios input_TELEFONO" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className="col-form-label" htmlFor="input_CELULAR">Celular</label>
                                                            <div className="input-group input-gpack2">
                                                                <input className="form-control" name='input_CELULAR' id='input_CELULAR' type="text" data-label="celular" placeholder='Celular' maxLength={10}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className='col-form-label' htmlFor="input_TIPO">Tipo usuario<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <select className='form-select' data-label='tipo de usuario' name='input_TIPO' id='input_TIPO' readOnly>
                                                                <option key={`key_option_tipos_3`} value='3' selected>CONDUCTOR</option>
                                                                </select>
                                                            </div>
                                                            <span className="text-danger txtVacios input_TIPO" style={{ display: 'none' }}><strong>Falta completar (Tipo usuario)</strong></span>
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-3'>
                                                            <label className='col-form-label' htmlFor="input_SUC">Sucursal<span className="text-danger">*</span></label>
                                                            <div className="input-group input-gpack2">
                                                                <select className='form-select' data-label='sucursal' name='input_SUC' id='input_SUC'>
                                                                <option hidden value={''}>Seleccione una sucursal</option>
                                                                {
                                                                    catalogos.sucursales.map(item => 
                                                                    <option key={`key_option_sucursales_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                                    )
                                                                }
                                                                </select>
                                                            </div>
                                                            <span className="text-danger txtVacios input_SUC" style={{ display: 'none' }}><strong>Falta completar (Sucursal)</strong></span>
                                                        </div>
                                                        <form id='form_permisos'>
                                                            <div className='card-permisos d-none'>
                                                                <div className="checkbox">
                                                                    <input id='input_9_10' name='input_9_10' type="checkbox" value={'1'} checked/>
                                                                    <label htmlFor='input_9_10'>Escanear paquetes como conductor</label>
                                                                </div>
                                                                <div className="checkbox">
                                                                    <input id='input_1_1' name='input_1_1' type="checkbox" value={'1'} checked/>
                                                                    <label htmlFor='input_1_1'>Acceso al TPV</label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>
                                        : '' }
                                    </div>
                                    <div className='row'>
                                        {/* <form id='formconductor' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"> */}
                                            <div className="form-group row">
                                                <div className="col-md-12 mb-3 mt-3">
                                                    <h6><strong>Datos generales del conductor</strong></h6>
                                                    <hr />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Nombre (s)<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_nombre" name="txt_nombre"
                                                            type="text"
                                                            placeholder="Nombre (s)"
                                                            defaultValue={conductor ? conductor.NOMBRE_DRV : ''}
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre (s))</strong></span>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Apellidos<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_apellidos" name="txt_apellidos"
                                                            type="text"
                                                            placeholder="Apellidos"
                                                            defaultValue={conductor ? conductor.APELLIDO_DRV : ''}
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos)</strong></span>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Correo<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_correo" name="txt_correo"
                                                            type="text"
                                                            placeholder="Correo"
                                                            defaultValue={conductor ? conductor.CORREO_DRV : ''}
                                                        readOnly = {esEditar ? true : false}
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Perfil<span className="text-danger">*</span></label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <Select
                                                            className="form-control form-control-md border border-dark"
                                                            data-label="Perfil"
                                                            placeholder="Seleccionar perfil"
                                                            id="list_perfil"
                                                            name="list_perfil"
                                                            tipoValue={1}
                                                            datos={[
                                                                {clave: "driver", label: "Conductor"},
                                                                {clave: "peoneta", label: "Asistente de conductor"}
                                                            ]}
                                                            value={conductor ? conductor.PERFIL_DRV : ''}
                                                            onChange={(datos) => {
                                                                esEditar ? 
                                                                setConductor((s) => ({...s, PERFIL_DRV: datos.item.clave}))
                                                                : setConductor()
                                                            }}
                                                            disableDropdown={esEditar ? true : false}
                                                            readOnly={esEditar ? true : false}
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios list_perfil" style={{ display: 'none' }}><strong>Falta completar (Perfil)</strong></span>
                                                </div>
                                                {/* <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">DNI</label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_dni" name="txt_dni"
                                                            type="text"
                                                            placeholder="DNI"
                                                            // defaultValue="Guías prepagadas"
                                                            // readOnly
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_dni" style={{ display: 'none' }}><strong>Falta completar (DNI)</strong></span>
                                                </div> */}
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Teléfono</label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control" 
                                                            id="txt_telefono" name="txt_telefono"
                                                            placeholder='Teléfono'
                                                            maxLength={10}
                                                            onInput={(e) => Tools.formatoNumber(e)}
                                                            defaultValue = {conductor ? conductor.TELEFONO_DRV : ''}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Número de licencia de conducir</label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_licencia" name="txt_licencia"
                                                            type="text"
                                                            placeholder="Número de licencia"
                                                            defaultValue={conductor ? conductor.NUMERO_LICENCIA_DRV : ''}
                                                        />
                                                    </div>
                                                </div>
                                            
                                                {/* <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationTooltip02">Depósitos</label>
                                                    <div className="input-group input-gpack2">
                                                        <div className="input-group-prepend"></div>
                                                        <input className="form-control"
                                                            autoComplete='off'
                                                            id="txt_depositos" name="txt_depositos"
                                                            type="text"
                                                            placeholder="Depósitos"
                                                            // defaultValue="Guías prepagadas"
                                                            // readOnly
                                                        />
                                                    </div>
                                                    <span className="text-danger txtVacios txt_depositos" style={{ display: 'none' }}><strong>Falta completar (Depósitos)</strong></span>
                                                </div> */}

                                                <div className="col-md-12 mb-3">
                                                    <label className="form-label">Descripción</label>
                                                    <div className="input-group">
                                                        <textarea style={{ width: '100%' }} rows={5} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={conductor ? conductor.DESCRIPCION_DRV : ''}></textarea>
                                                    </div>
                                                    <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                                </div>
                                            </div>
                                            {esEditar ? 
                                            <div className="col-md-12 mb-3">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-purple d-flex align-items-center"
                                                        type="button"
                                                        onClick={(e) => actualizar(conductor.ID_DRV)}
                                                        disabled = {isUpdating}
                                                    >
                                                        {/* Actualizar conductor */}
                                                        {isUpdating ? 'Editando...' : 'Editar conductor'}
                                                    </button>
                                                </div>
                                            </div>
                                            : ''}

                                            {!esEditar ? <div className="col-md-12 mb-3">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-purple d-flex align-items-center"
                                                        type="button"
                                                        onClick={(e) => guardar()}
                                                        disabled = {isUpdating}

                                                    >
                                                        {/* Guardar conductor */}
                                                        {isUpdating ? 'Guardando...' : 'Guardar conductor'}
                                                        </button>
                                                </div>
                                            </div> : ''}


                                        {/* </form> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>)
}

export default Default