import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import tools from '../../js/tools';
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Send } from 'react-feather';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import Select from '../common/select-basic';
import { Sliders } from 'react-feather';


const Default = ({
    esRefresh, setEsRefresh
}) => {
    // const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const [filtros, setFiltros] = useState({
        suc: '',
    })

    const [catalogos, setCatalogos] = useState({
        ctgSuc: [],
        ctgEstatus: [],
    })


    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: '' }))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s }))
    }

    const onSelectSuc = (datos) => {
        setFiltros((s) => ({ ...s, suc: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectEstatus = (datos) => {
        setFiltros((s) => ({ ...s, estatus: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s, fecha: datos }))
        setEsRefreshLoc((s) => !s)
    }



    const colorSelector = (val) => {
        switch (val) {
            case 0:
                return '#dc3545'
            case 1:
                return ''
            case 2:
                return '#ffc107'
            case 3:
                return '#fd7e14'
            case 4:
                return '#17a2b8'
            case 5:
                return '#28a745'
            default:
                break;
        }
    }

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_prod_serv_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_suc_todas',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_estatus_paquete',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgEstatus: response.datos.ctgEstatus }))
        })

    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={Send} title={'Envíos'} parent={['envios']} />
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                            <Fragment>
                                <FilterRemove
                                    className={'m-1'}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                    onClick={() => limpiarFiltro()}
                                />
                                <Filtros
                                    title={<Fragment>
                                        <Sliders size={14} /> Filtros</Fragment>}
                                    items={[
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar Sucursal</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Sucursal"
                                                        placeholder="Seleccionar Sucursal"
                                                        id="list_suc"
                                                        name="list_suc"
                                                        tipoValue={1}
                                                        value={filtros.suc ? filtros.suc : ''}
                                                        datos={catalogos.ctgSuc}
                                                        onChange={onSelectSuc}

                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div>
                                                    <label className="form-label">Filtrar por estatus</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Entrada"
                                                        placeholder="Seleccionar estatus"
                                                        id="list_est"
                                                        name="list_est"
                                                        tipoValue={1}
                                                        value={filtros.estatus ? filtros.estatus : ''}
                                                        datos={catalogos.ctgEstatus}
                                                        onChange={onSelectEstatus}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label htmlFor="buscar">
                                                        Filtrar por fecha
                                                    </label>
                                                    <input
                                                        id="fecha"
                                                        name="fecha"
                                                        type="date"
                                                        className="form-control"
                                                        value={filtros.fecha ? filtros.fecha : ''}
                                                        onChange={(e) => {
                                                            onSelectFecha(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                        }
                                    ]}
                                />
                            </Fragment>
                        </div>
                        <div className="col-xl-12">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                htmlBtnPag={
                                    <button
                                        className="btn btn-purple"
                                        type="button"
                                        onClick={() => {
                                            navigate('/envios/nuevo')
                                        }}
                                    > Nuevo envío
                                    </button>}
                                headers={[
                                    {
                                        nombre: 'FOLIO', tipo: 'html', texto: 'FOLIO_SE', html: (d) => {
                                            return <div>{d.FOLIO_SE}</div>
                                        }
                                    },
                                    {
                                        nombre: 'CLIENTE', tipo: 'html', texto: 'NOMBRE_CL', html: (d) => {
                                            return <div>{d.REM_SE}</div>
                                        }
                                    },
                                    {
                                        nombre: 'SUCURSAL', tipo: 'html', texto: 'SUCURSAL', html: (d) => {
                                            return <div>{d.SUCURSAL}</div>
                                        }
                                    },
                                    // {
                                    //     nombre: 'REMITENTE', tipo: 'html', texto: 'REM_SE', html: (d) => {
                                    //         return <div>
                                    //             {d.REM_SE}
                                    //         </div>
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'DESTINATARIO', tipo: 'html', texto: 'DES_SE', html: (d) => {
                                    //         return <div>
                                    //             {d.DES_SE}
                                    //         </div>
                                    //     }
                                    // },
                                    {
                                        nombre: 'ORIGEN', tipo: 'html', texto: 'ORIGEN', html: (d) => {
                                            return <div>{d.ORIGEN}</div>
                                        }
                                    },
                                    {
                                        nombre: 'DESTINO', tipo: 'html', texto: 'DESTINO', html: (d) => {
                                            return <div>{d.DESTINO}</div>
                                        }
                                    },
                                    {
                                        nombre: 'ESTATUS ENVÍO', tipo: 'html', texto: 'DESCRIPCION_EST', html: (d) => {
                                            return <>
                                                <div>
                                                    <div className='row'>
                                                        {d.NOMBRE_EST != '' || d.NOMBRE_EST != null ?
                                                            <div className='col-md-6 p-0'>
                                                                <div className="card border-secondary mb-1 text-center">
                                                                    <div className="card-body text-secondary p-0">
                                                                        <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ESTATUS_ENVIO_SE) }}>{d.NOMBRE_EST}</strong><br />
                                                                        {/* <p className="card-text" style={{fontSize: 12}}>{d.FECHA_ALTA_SE_TEXT}</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='col-md-6 p-0 text-center'>
                                                                Sin estatus
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        } 
                                    },
                                    {
                                        nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/envios/${d.ID_SE}`)
                                                    }}
                                                />
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ id_ps: d.ID_PS })
                                                            },
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_envio_realizados'}
                                refresh={esRefreshLoc}
                                body={{ ...filtros }}
                                rowsPerPage={10}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;