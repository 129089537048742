import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnVer, BtnEliminar, BtnAccion } from '../common/table_buttons'
import Tools from '../../js/tools'
import Table from '../common/tabla'
import Breadcrumb from '../common/breadcrumbv2';
import { CreditCard } from 'react-feather';
import notie from 'notie'
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';

const Default = (
    { esRefresh, setEsRefresh }
) => {
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const navigate = useNavigate()
    // const [esRefresh, setEsRefresh] = useState(true)
    const [filtros, setFiltros] = useState({
        estatus: '',
    })


    const limpiarFiltro = () => {
        setFiltros((s) => ({ estatus: '' }))
        setEsRefreshLoc((s) => !s)
    }
    const onSelectEst = (datos) => {
        let estatus = document.getElementById('list_est').value
        setFiltros((s) => ({ ...s, estatus }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s, fecha: datos }))
        setEsRefreshLoc((s) => !s)
    }

    const colorSelector = (val) => {
        switch (val) {
            case 'Activo':
                return '#ffc107'
            case 'Inactivo':
                return '#dc3545'
            default:
                break;
        }
    }

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se desactivará <strong>${d.NOMBRES_CONTACTO}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_cliente_eliminar',
                    method: 'POST',
                    body: { id: d.ID_CL },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    const onClickActivar = (d) => {
        notie.confirm({
            text: `Se activará <strong>${d.NOMBRES_CONTACTO}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, activar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_cliente_activar',
                    method: 'POST',
                    body: { id: d.ID_CL },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    const onClickReporte = (tipo) => {
        let filename = ''
        fetch('/rt_generar_reporte_empresas', {
            method: "POST",
            body: JSON.stringify({ tipo_reporte: tipo }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    return { estado: 0, ...(await res.json()) }
                } else {
                    filename = res.headers.get('filename')
                    return { estado: 1, blob: await res.blob() }
                }
            }).then((response) => {
                if (response.estado) {
                    let url = window.URL.createObjectURL(response.blob),
                        a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    // Toast.onClickToastWarn({ html: <div>No se pudo descargar el archivo vuelva a intentar</div> })
                }
            })
            .catch((error) => {
                // Toast.onClickToastWarn({ html: <div>No se pudo descargar el archivo vuelva a intentar</div> })
            })
    }

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])


    return (
        <Fragment>
            <Breadcrumb icon={CreditCard} title={'Clientes'} parent={['clientes']} />
            <div className="card d-print-none" >
                <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                    <Fragment>
                        <FilterRemove
                            className={'m-1'}
                            style={{ cursor: 'pointer', fontSize: 14 }}
                            onClick={limpiarFiltro}
                        />
                        <Filtros
                            title={<Fragment>
                                <Sliders size={14} /> Filtros</Fragment>}
                            items={[
                                {
                                    element:
                                        <div style={{ width: 300 }}>
                                            <label className="form-label">Filtrar por estatus</label>
                                            <select
                                                className="form-control form-control-md border"
                                                data-label="Sucursal"
                                                id="list_est"
                                                name="list_est"
                                                value={filtros.estatus ? filtros.estatus : ''}
                                                onChange={onSelectEst}
                                            >
                                                <option value={''} defaultValue>Seleccionar estatus</option>
                                                <option value={'1'} >Activo</option>
                                                <option value={'0'} >Inactivo</option>
                                            </select>
                                        </div>
                                },

                                {
                                    element:
                                        <div style={{ width: 300 }}>
                                            <label htmlFor="buscar">
                                                Filtro por fecha de alta
                                            </label>
                                            <input
                                                id="fecha"
                                                name="fecha"
                                                type="date"
                                                className="form-control"
                                                value={filtros.fecha ? filtros.fecha : ''}
                                                onChange={(e) => {
                                                    onSelectFecha(e.target.value)
                                                }}
                                            />
                                        </div>
                                }
                            ]}
                        />
                    </Fragment>
                </div>


                <div className="mt-3">
                    <Table
                        cargando={false}
                        htmlBtnPag={
                            <button
                                className="btn btn-purple btn-nuevo"
                                type="button"
                                onClick={() => { navigate('/clientes/nuevo') }}
                            > Nuevo cliente
                            </button>}
                        headers={[

                            {
                                nombre: 'CLIENTE', tipo: 'html', texto: 'CLIENTE', html: (d) => {
                                    return <div className='d-flex align-items-start'>
                                        <p>
                                            <strong><span style={{ textTransform: 'uppercase' }}>{d.NOMBRES_CONTACTO} {d.APELLIDOS_CONTACTO}</span></strong>
                                            <br />{d.TELEFONO ? `TEL. ${d.TELEFONO}` : ''}
                                            <br />{d.CELULAR ? `CEL. ${d.CELULAR}` : ''}
                                        </p>
                                    </div>
                                }
                            },
                            {
                                nombre: 'EMPRESA', tipo: 'html', texto: 'EMPRESA', html: (d) => {
                                    return <div ><strong>{d.EMPRESA_CL}</strong></div>
                                }
                            },
                            {
                                nombre: 'CORREO', tipo: 'html', texto: 'CORREO', html: (d) => {
                                    return <div>{d.CORREO}</div>
                                }
                            },
                            {
                                nombre: 'ESTATUS', tipo: 'html', texto: 'ESTATUS', html: (d) => {
                                    return <>
                                        <div>
                                            <div className='row'>
                                                {d.ACTIVO_CL_TEXT != '' || d.ACTIVO_CL_TEXT != null ?
                                                    <div className='col-md-6 p-0'>
                                                        <div className="card border-secondary mb-1 text-center">
                                                            <div className="card-body text-secondary p-0">
                                                                <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ACTIVO_CL_TEXT) }}>{d.ACTIVO_CL_TEXT}</strong><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-md-6 p-0 text-center'>
                                                        Sin estatus
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            },
                            {
                                nombre: 'DETALLE', tipo: 'html', texto: 'DETALLE',
                                html: (d) => {
                                    return <div>
                                        <BtnVer
                                            onClick={() => { navigate('/clientes/' + Tools.urlFormat(d.EMPRESA_CL)) }}
                                        />
                                        {d.ACTIVO_CL == 1 ?
                                            <BtnEliminar
                                                onClick={() => onClickEliminar(d)}
                                            />
                                            :
                                            <BtnAccion
                                                onClick={() => onClickActivar(d)}
                                            />
                                        }
                                    </div>
                                }
                            }
                        ]}
                        totalField={'NR'} /* NR = número de resultados totales */
                        path={'rt_clientes'}
                        refresh={esRefreshLoc}
                        body={{ ...filtros }}
                        rowsPerPage={10}
                        paginadorTop={true}
                    >
                    </Table>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;