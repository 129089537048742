import React, { Fragment, useState, useEffect } from 'react';
import { Truck } from 'react-feather';
import { UserPlus } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import Tabla from '../common/tabla';
import { useNavigate } from 'react-router-dom';
import tools from '../../js/tools';
import { BtnVer, BtnEliminar, BtnAccion } from '../common/table_buttons'
import Tools from '../../js/tools'
import notie from 'notie'
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';

const Default = ({
    esRefresh, setEsRefresh
}) => {
    const navigate = useNavigate();
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const [filtros, setFiltros] = useState({
        estatus: '',
    })

 

    const colorSelector = (val) => {
        switch (val) {
            case 'Activo':
                return '#ffc107'
            case 'Inactivo':
                return '#dc3545'
            default:
                break;
        }
    }

    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: '' }))
        setEsRefreshLoc((s) => !s)
    }

    
    const onSelectEstatus = (datos) => {
        let estatus = document.getElementById('list_est').value
        setFiltros((s) => ({ ...s, estatus }))
        setEsRefreshLoc((s) => !s)
    }


    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se desactivará <strong>${d.NOMBRE}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_sucursales_eliminar',
                    method: 'POST',
                    body: { id: d.ID },
                }, (response) => {


                    if (response.estatus == 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)

                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    const onClickActivar = (d) => {
        notie.confirm({
            text: `Se activará <strong>${d.NOMBRE}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, activar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_sucursales_activar',
                    method: 'POST',
                    body: { id: d.ID },
                }, (response) => {

                    if (response.estatus == 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)

                })
            },
            cancelCallback: function (value) {
            },
        })
    }



    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])

    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Sucursales'} parent={['sucursales']} />

            <div className="col-sm-12">
                <div className="card">
                    <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                        <Fragment>
                            <FilterRemove
                                className={'m-1'}
                                style={{ cursor: 'pointer', fontSize: 14 }}
                                onClick={() => limpiarFiltro()}
                            />
                            <Filtros
                                title={<Fragment>
                                    <Sliders size={14} /> Filtros</Fragment>}
                                items={[
                                    
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar por estatus </label>
                                                <select
                                                    className="form-control form-control-md border"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar estatus"
                                                    id="list_est"
                                                    name="list_est"
                                                tipoValue={1}
                                                value={filtros.estatus ? filtros.estatus : ''}
                                                onChange={onSelectEstatus}
                                                >
                                                    <option value={''} defaultValue>Seleccionar estatus</option>
                                                    <option value={'1'} >Activo</option>
                                                    <option value={'0'} >Inactivo</option>
                                                </select>
                                            </div>
                                    },

                                   
                                ]}
                            />
                        </Fragment>
                    </div>
                    <div className="card-body">

                        <Tabla
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => { navigate('/sucursales/nuevo') }}
                                > Nueva sucursal
                                </button>}
                            headers={[
                                // {nombre: 'ID', texto: 'ID'},
                                { nombre: 'NOMBRE', texto: 'NOMBRE' },
                                { nombre: 'ESTADO', texto: 'ESTADO' },
                                { nombre: 'CIUDAD', texto: 'CIUDAD' },
                                {
                                    nombre: 'ESTATUS', tipo: 'html', texto: 'ACTIVO_SUC', html: (d) => {
                                        return <>
                                            <div>
                                                <div className='row'>
                                                    {d.ACTIVO_SUC != '' || d.ACTIVO_SUC != null ?
                                                        <div className='col-md-6 p-0'>
                                                            <div className="card border-secondary mb-1 text-center">
                                                                <div className="card-body text-secondary p-0">
                                                                    <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ACTIVO_TEXT) }}>{d.ACTIVO_TEXT}</strong><br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-6 p-0 text-center'>
                                                            Sin estatus
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                },
                                // { nombre: 'Fecha', tipo: 'individual', texto: 'FECHA_ALTA_RAC_TEXT' },
                                {
                                    nombre: 'DETALLE', texto: 'DETALLE', tipo: 'html',
                                    html: (d) => {
                                        // return <div>
                                        //     <button
                                        //         className="btn btn-outline-success"
                                        //         type="button"
                                        //         onClick={() => {
                                        //             navigate(`/sucursales/${tools.urlFormat(d.NOMBRE)}`);
                                        //         }}
                                        //     > <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></button>
                                        // </div>
                                        return (
                                            <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/sucursales/${tools.urlFormat(d.NOMBRE)}`);
                                                    }}
                                                />
                                                {d.ACTIVO_SUC == 1 ?
                                                    <BtnEliminar
                                                        onClick={() => onClickEliminar(d)}
                                                    />
                                                    :
                                                    <BtnAccion
                                                        onClick={() => onClickActivar(d)}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'rt_sucursales_registros'}
                            refresh={esRefreshLoc}
                            body={{...filtros}}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Tabla>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;