import React, { Fragment, useState, useEffect } from 'react';
import { UserPlus } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import Tabla from '../common/tabla';
import { useNavigate } from 'react-router-dom';
import tools from '../../js/tools';
import Tools from '../../js/tools'
import notie from 'notie'
import { BtnVer, BtnEliminar, BtnAccion } from '../common/table_buttons'
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';


const Default = (
    { esRefresh, setEsRefresh }
) => {
    /* ==================== HOOKS ==================== */

    /* ==================== CONSTANTES ==================== */
    const navigate = useNavigate();
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const [filtros, setFiltros] = useState({
        suc: '',
    })

    const [catalogos, setCatalogos] = useState({
        ctgSuc: []

    })


    const onSelectSuc = (datos) => {
        setFiltros((s) => ({ ...s, suc: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: '' }))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s }))
    }

    const onSelectEstatus = (datos) => {
        let estatus = document.getElementById('list_est').value
        setFiltros((s) => ({ ...s, estatus }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s, fecha: datos }))
        setEsRefreshLoc((s) => !s)
    }


    const colorSelector = (val) => {
        switch (val) {
            case 'Activo':
                return '#ffc107'
            case 'Inactivo':
                return '#dc3545'
            default:
                break;
        }
    }

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se desactivará <strong>${d.NOMBRE}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_usuario_tpv_eliminar',
                    method: 'POST',
                    body: { id: d.ID },
                }, (response) => {


                    if (response.estatus == 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)

                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    const onClickActivar = (d) => {
        notie.confirm({
            text: `Se activará <strong>${d.NOMBRE}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, activar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_usuario_tpv_activar',
                    method: 'POST',
                    body: { id: d.ID },
                }, (response) => {

                    if (response.estatus == 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefreshLoc((s) => !s)

                })
            },
            cancelCallback: function (value) {
            },
        })
    }


    /* ==================== USE EFFECT ==================== */
    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_suc_todas',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })


    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])
    /* ==================== FUNCIONES ==================== */

    return (
        <Fragment>
            <Breadcrumb icon={UserPlus} title={'Usuarios'} parent={['usuarios-tpv']} />
            <div className="col-sm-12">
                <div className="card">
                    <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                        <Fragment>
                            <FilterRemove
                                className={'m-1'}
                                style={{ cursor: 'pointer', fontSize: 14 }}
                                onClick={() => limpiarFiltro()}
                            />
                            <Filtros
                                title={<Fragment>
                                    <Sliders size={14} /> Filtros</Fragment>}
                                items={[
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar Sucursal</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Sucursal"
                                                    placeholder="Seleccionar Sucursal"
                                                    id="list_suc"
                                                    name="list_suc"
                                                    tipoValue={1}
                                                    value={filtros.suc ? filtros.suc : ''}
                                                    datos={catalogos.ctgSuc}
                                                    onChange={onSelectSuc}

                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por estatus </label>
                                                <select
                                                    className="form-control form-control-md border"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar estatus"
                                                    id="list_est"
                                                    name="list_est"
                                                    tipoValue={1}
                                                    value={filtros.estatus ? filtros.estatus : ''}
                                                    onChange={onSelectEstatus}
                                                >
                                                    <option value={''} defaultValue>Seleccionar estatus</option>
                                                    <option value={'1'} >Activo</option>
                                                    <option value={'0'} >Inactivo</option>
                                                </select>
                                            </div>
                                    },

                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label htmlFor="buscar">
                                                    Filtrar por fecha
                                                </label>
                                                <input
                                                    id="fecha"
                                                    name="fecha"
                                                    type="date"
                                                    className="form-control"
                                                    value={filtros.fecha ? filtros.fecha : ''}
                                                    onChange={(e) => {
                                                        onSelectFecha(e.target.value)
                                                    }}
                                                />
                                            </div>
                                    }
                                ]}
                            />
                        </Fragment>
                    </div>


                    <div className="card-body">
                        <Tabla
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple"
                                    type="button"
                                    onClick={() => {
                                        navigate('/usuarios-tpv/nuevo')
                                    }}
                                > Nuevo usuario
                                </button>}

                            headers={[
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                        return <div>{d.NOMBRE}</div>
                                    }
                                },
                                {
                                    nombre: 'CORREO', tipo: 'html', texto: 'CORREO', html: (d) => {
                                        return <div>{d.CORREO}</div>
                                    }
                                },
                                {
                                    nombre: 'TELÉFONO', tipo: 'html', texto: 'TELEFONO', html: (d) => {
                                        return <div>{d.TELEFONO}</div>
                                    }
                                },
                                {
                                    nombre: 'SUCURSAL', tipo: 'html', texto: 'SUCURSAL', html: (d) => {
                                        return <div>{d.SUCURSAL}</div>
                                    }
                                },
                                {
                                    nombre: 'FECHA ALTA', tipo: 'html', texto: 'FECHA', html: (d) => {
                                        return <div>{d.ALTA}</div>
                                    }
                                },
                                {
                                    nombre: 'ESTATUS', tipo: 'html', texto: 'ESTATUS', html: (d) => {
                                        return <>
                                            <div>
                                                <div className='row'>
                                                    {d.ACTIVO_TEXT != '' || d.ACTIVO_TEXT != null ?
                                                        <div className='col-md-6 p-0'>
                                                            <div className="card border-secondary mb-1 text-center">
                                                                <div className="card-body text-secondary p-0">
                                                                    <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ACTIVO_TEXT) }}>{d.ACTIVO_TEXT}</strong><br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-6 p-0 text-center'>
                                                            Sin estatus
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return (
                                            <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/usuarios-tpv/detalle/${d.ID}`);
                                                    }}
                                                />
                                                {d.ACTIVO_UT == 1 ? 
                                                    <BtnEliminar
                                                        onClick={() => onClickEliminar(d)}
                                                    />
                                                 : 
                                                    <BtnAccion
                                                        onClick={() => onClickActivar(d)}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                }
                                
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'rt_tpv_usuarios_registros'}
                            refresh={esRefreshLoc}
                            body={{ ...filtros }}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Tabla>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;