import React, { Fragment, useState, useEffect } from 'react';
import Tabla from '../common/tabla_estatica';
import Tools from '../../js/tools'
import notie from 'notie'
import { useNavigate } from 'react-router-dom';

const Default = ({
    esRefreshEnvio,
    setEsRefreshEnvio
}) => {
    const [envio, setEnvio] = useState(Tools.local.getObjectJson('envio') || [])
    const [totales, setTotales] = useState({subtotal: '0.00', iva: '0.00', total: '0.00'})
    const navigate = useNavigate();
    const colorSelector = (val) => {
        switch (val) {
            case 0:
                return '#dc3545'
            case 1:
                return ''
            case 2:
                return '#ffc107'
            case 3:
                return '#fd7e14'
            case 4:
                return '#17a2b8'
            case 5:
                return '#28a745'
            default:
                break;
        }
    }
    

    const onNuevaCantidad = (item, tipo = 1) => {
        let d = item.d.servicio.datos
        let servicio = item.d.servicio.datos
        let selectCliente = item.d.selectCliente
        let formCarga = item.d.formCarga ? item.d.formCarga : {}
        let cantidad = 1
        let prodServ = envio.find((v, i) => i == item.i)
        if (prodServ) {
            cantidad = prodServ.precio.cantidad

            if (tipo) {
                cantidad += 1
            } else {
                cantidad -= 1
            }

            if (cantidad < 1) {
                cantidad = 1
            }
        }

        Tools.toolFetch({
            ruta: 'rt_envio_precios',
            method: 'POST',
            body: {
                idPs: servicio.ID_PS,
                listSuc: selectCliente.value,
                txtCantidad: cantidad,
                cobZona: formCarga.cobZona || 0,
                txtPesoReal: formCarga.txtPesoReal || 0,
                txtPesoVol: formCarga.txtPesoVol || 0
            }
        }, (response) => {
            let datos = response.datos
            if (response.estatus === 'NO-PRECIO') {
                return notie.alert({ type: 'warning', text: `No hay precios asignados para este servicio` })
            }

            let envio = Tools.local.getObjectJson('envio')
            // Actualiza registro si ya existe
            if (prodServ) {
                envio = envio.map((v, i) => {
                    let precio = v.precio

                    if (i === item.i) {
                        precio.cantidad = cantidad
                        precio.precio = datos.montos.PRECIO_PS
                        precio.txtSubtotal = datos.montos.SUBTOTAL
                        precio.txtIva = datos.montos.IVA
                        precio.txtTotal = datos.montos.TOTAL
                        v.precio = precio
                        return v
                    } else {
                        return v
                    }
                })

                Tools.local.addObjectJson('envio', envio)
                setEnvio(envio)
                setEsRefreshEnvio((s) => !s)
                return
            }
        })
    }

    const onEnviar = () => {
        let selectCliente = Tools.local.getObjectJson('selectCliente')
        let selectSuc = Tools.local.getObjectJson('selectSuc')

        Tools.toolFetch({
            ruta: 'rt_envio_guardar',
            method: 'POST',
            body: {
                selectSuc: selectSuc.clave,
                selectCliente: selectCliente.value,
                envio
            }
        }, (response) => {
            console.log(response)
        })
    }

    useEffect(() => {
        let _envio = Tools.local.getObjectJson('envio') || []
        Tools.toolFetch({
            ruta: 'rt_envio_totales',
            method: 'POST',
            body: {
                envio: _envio || []
            }
        }, (response) => {
            let datos = response.datos
            setTotales({
                subtotal: datos.SUBTOTAL, 
                iva: datos.IVA, 
                total: datos.TOTAL
            })
        })

        setEnvio(_envio)
    }, [esRefreshEnvio])

    return (
        <Fragment>
            <div className='row mt-5 mt-md-0'>
                <div className='col-lg-12'>
                    <strong>Subtotal:</strong> ${totales.subtotal}<br/>
                    <strong>Iva:</strong> ${totales.iva}<br/>
                    <strong>Total:</strong> ${totales.total}
                    <br></br><button className='btn btn-purple mt-4' onClick={() => onEnviar()}>Envíar</button><br/>
                </div>
                <div className='col-lg-12 tabla-envio-solicitud'>
                    <Tabla
                        headers={[
                            {
                                tipo: 'html',
                                nombre: <div className='m-0 p-0'>
                                    <h6>Pedido</h6>
                                    <button className='btn btn-back' onClick={() => {
                                        if (envio.length === 0) {
                                            return notie.alert({ type: 'warning', text: `No hay servicios agregados`, time: 2 })
                                        }

                                        notie.confirm({
                                            text: `Se eliminara todo el envío ¿Estas de acuerdo?`,
                                            submitText: 'Si, Eliminar',
                                            cancelText: 'No, Cancelar',
                                            cancelCallback: function () {
                                            },
                                            submitCallback: function () {
                                                localStorage.removeItem('envio')
                                                localStorage.removeItem('selectCliente')
                                                setEnvio([])
                                            }
                                        })
                                    }}>Eliminar</button>
                                </div>,
                                clave: 'Pedido',
                                style: { padding: 2 },
                                html: (d, i) => {
                                    let servicio = d.servicio.datos
                                    let precio = d.precio
                                    return <div style={{ width: '100%' }}>
                                        <div className="card-body p-0">
                                            <h4 className="card-title pb-0 mb-0"><strong>{servicio.NOMBRE_PS}</strong></h4>
                                            <strong className='mb-2'><span style={{ fontSize: '1em' }}>{servicio.CLAVE_CPS}</span></strong>
                                            <div className='row'>
                                                <div className={`col-lg-3`}>
                                                    <div className="card pb-0 mb-0">
                                                        <img src={servicio.IMG_PORTADA_URL} width={70} height={70} />
                                                    </div>
                                                </div>
                                                <div className={`col-lg-7 mt-4 mt-lg-0`}>
                                                    <div className="pb-0 mb-0">
                                                        <h6 className="card-title"><span style={{ color: '#3B1195' }}>{precio.cantidad} x ${Tools.moneyFormat(precio.precio)} = ${Tools.moneyFormat(precio.txtSubtotal)}</span></h6>
                                                        <button className='btn btn-menos me-1' onClick={() => {
                                                            onNuevaCantidad({ d, i }, 0)
                                                        }}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                        <button className='btn btn-mas ms-1' onClick={() => {
                                                            onNuevaCantidad({ d, i }, 1)
                                                        }}><i className="fa fa-plus" aria-hidden="true"></i></button><br />
                                                        {servicio.TIPO_PS == 1 ?
                                                            <button className="btn btn-purple btn-nuevo mt-3 w-100" onClick={() => {
                                                                setTimeout(() => window.location.reload('.'), 1000)
                                                                envio[i].idEditar = i
                                                                envio[i].nuevoEnvio = 1
                                                                Tools.local.cargarObjeto(envio[i])
                                                            }}>Editar</button>
                                                            : ''}
                                                        <button className='btn btn-back mt-2 w-100' onClick={() => {
                                                            let item = envio[i].servicio.datos
                                                            notie.confirm({
                                                                text: `Eliminar registro <strong>${item.NOMBRE_PS}</strong>`,
                                                                submitText: 'Si, Eliminar',
                                                                cancelText: 'No, Cancelar',
                                                                cancelCallback: function () {
                                                                },
                                                                submitCallback: function () {
                                                                    let _envio = envio.filter((v, l) => l !== i)
                                                                    if (_envio.length) {
                                                                        Tools.local.addObjectJson('envio', _envio)
                                                                    } else {
                                                                        localStorage.removeItem('envio')
                                                                        localStorage.removeItem('selectCliente')
                                                                    }
                                                                    setEnvio(_envio)
                                                                }
                                                            })
                                                        }}>Eliminar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            }
                        ]}
                        rows={envio}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Default;