import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import Select from '../common/select-basic'
import { Truck } from 'react-feather';
import notie from 'notie';
import Tools from '../../js/tools';
import SelectFetch from '../common/select-fetch';
//FORMULARIOS

import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate } from 'react-router-dom';

const Default = (props) => {
    const [catalogos, setCatalogos] = useState({});
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState({value: '', label: 'Seleccione un tipo'})

    const changeState = (e) => {
        let clave = (e);
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: { clave },
        }, (response) => {
            setCatalogos({ ...catalogos, ciudades: response.datos })
        })
    }

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('form_sucursal', { ignore: selectedValue.label === 'Física' ? ['input_num_int', 'input_referencias'] : ['input_buscar', 'input_calle', 'input_num_ext', 'input_num_int', 'input_colonia', 'input_cp', 'select_estado', 'select_ciudad', 'input_referencias', 'input_longitud', 'input_latitud'] });

        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.stringIsEmpty} es requerido`, type: 3, time: 4});
            return;
        }
        if (!Tools.validMail(datos.data.input_correo)) {
            document.querySelector(`.input_correo`).style['display'] = 'block'
            notie.alert({text: `Ingresa un correo válido.`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_telefono.length < 10){
            document.querySelector(`.input_telefono`).style['display'] = 'block'
            notie.alert({text: `El campo Teléfono debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_celular.length < 10){
            document.querySelector(`.input_celular`).style['display'] = 'block'
            notie.alert({text: `El campo Celular debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }
        if( selectedValue.label === 'Física' & datos.data.input_cp.length < 5){
            document.querySelector(`.input_cp`).style['display'] = 'block'
            notie.alert({text: `El campo Código postal debe ser de 5 caracteres`, type: 3, time: 4});
            return;
        }

        fetch('../rt_sucursales_nuevo', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => res.json())
            .then((res) => {
                console.log(789,res)
                if (res.estatus === 'OK') {
                    if (res.suc.RESULTADO === 'AGREGADO') {
                        notie.alert({ text: 'Agregado con éxito', type: 1, time: 4 })
                        setTimeout(() => {
                            notie.alert({ text: 'Será redireccionado a la sección de datos fiscales para vincular estos datos', type: 1, time: 4 })
                            setTimeout(() => {
                                navigate('/empresa/datos-fiscales')
                            }, 4000);
                        }, 4000);
                    } else {
                        return notie.alert({ text: 'La sucursal ya se encuentra registrada', type: 2, time: 5 });
                    }
                } 

                if (res.estatus == 'correo-invalido') {
                    document.querySelector(`.input_correo`).style['display'] = 'block'
                    return notie.alert({ text: 'El correo ingresado no es válido', type: 2, time: 5 });
                } 
                
                if (res.estatus == 'cp-invalido') {
                    document.querySelector(`.input_cp`).style['display'] = 'block'
                    return notie.alert({ text: 'El código postal debe contener 5 dígitos.', type: 2, time: 5 });
                } 

                if (res.estatus == 'tel-invalido') {
                    document.querySelector(`.input_telefono`).style['display'] = 'block'
                    return notie.alert({ text: 'El número de teléfono  debe contener 10 dígitos.', type: 2, time: 5 });
                } 

                if (res.estatus == 'cel-invalido') {
                    document.querySelector(`.input_celular`).style['display'] = 'block'
                    return notie.alert({ text: 'El número de celular debe contener 10 dígitos', type: 2, time: 5 });
                } 
                
                if (res.estatus == 'ERROR') {
                    return notie.alert({ text: 'No se pudo agregar, inténtelo más tarde.', type: 3, time: 5 });    
                } 
                
            }).catch(function (err) {
                console.log(err);

                notie.alert({ text: 'No se pudo agregar, inténtelo más tarde', type: 3, time: 5 });
            })
    }

    function validarNumeros(input) {
        input.target.value = input.target.value.replace(/[^0-9]/g, '');
    }

    function selectedType(e) {
        console.log(e);
        let value = e.label
        setSelectedValue({ value: e.value, label: e.label })
        if (value === "Física") {
            document.getElementById('form_address').removeAttribute('hidden')
        } else {
            document.getElementById('form_address').setAttribute('hidden', 'hidden')
        }
    }

    function populateAddressFields(addressComponents) {
        const componentForm = {
            administrative_area_level_1: `select_estado`,
            street_number: `input_num_ext`,
            route: `input_calle`,
            sublocality: `input_colonia`,
            postal_code: `input_cp`,
            locality: `select_ciudad`,
        };
        for (let component in componentForm) {
            document.getElementById(componentForm[component]).value = '';
        }
        document.getElementById('input_referencias').value = '';
        let cityComponent = null;
        addressComponents.forEach(component => {
            component.types.forEach(type => {
                if (componentForm[type]) {
                    if (type === 'locality') {
                        cityComponent = component;
                    } else if (type === 'administrative_area_level_1') {
                        for (let i = 0; i < document.getElementById(componentForm[type]).options.length; i++) {
                            if (document.getElementById(componentForm[type]).options[i].text === component.long_name) {
                                document.getElementById(componentForm[type]).selectedIndex = i;
                                document.getElementById(componentForm[type]).dispatchEvent(new Event('change', { bubbles: true, cancelable: true, }));
                                break;
                            }
                        }
                    } else {
                        document.getElementById(componentForm[type]).value = component.long_name;
                    }
                }
            });
        });
    
        if (cityComponent) {
            setTimeout(() => {
                for (let i = 0; i < document.getElementById(componentForm['locality']).options.length; i++) {
                    if (document.getElementById(componentForm['locality']).options[i].text === cityComponent.long_name) {
                        document.getElementById(componentForm['locality']).selectedIndex = i;
                        document.getElementById(componentForm['locality']).dispatchEvent(new Event('change'));
                        break;
                    }
                }
            }, 1500);
        }
    }
    
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            return new Promise((resolve, reject) => {
                if (window.google) {
                    resolve(window.google);
                } else {
                    const script = document.createElement('script');
                    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAsuT7ZRnpRj9ToHba9X__jOUQ2kYNUMGw&libraries=places";
                    script.async = true;
                    script.defer = true;
                    script.onload = () => resolve(window.google);
                    script.onerror = reject;
                    document.head.appendChild(script);
                }
            });
        };

        async function initMap() {
            const google = await loadGoogleMapsScript();
            const [{ Map }] = await Promise.all([
                google.maps.importLibrary("places")
            ]);
            const input = document.getElementById(`input_buscar`)
            const autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener('place_changed', async () => {
                const place = autocomplete.getPlace();
                populateAddressFields(place?.address_components);
                if (!place.geometry) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }
                const lat = place.geometry.location.lat();
                const lon = place.geometry.location.lng();
                document.getElementById(`input_latitud`).value = lat
                document.getElementById(`input_longitud`).value = lon
            });
        }

        initMap();
    }, []);

    useEffect(() => {
        fetch('../rt_sucursales_catalogos', {
            method: 'POST',
            body: '{}',
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
            .then(function (res) {
                if (res.estatus === 'OK') {
                    setCatalogos(res.datos)
                }
                else
                    throw Error(res.estatus);
            }).catch(function (err) {
                notie.alert({ text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5 });
            })
    }, [])

    return (
        <Fragment>
            <Breadcrumb icon={Truck} title={'Nueva sucursal'} parent={['sucursales', 'nuevo']} />
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_sucursal' className="theme-form">
                            {/* <div className='form-group row'>
                                <label className='col-sm-3 col-lg-2 col-form-label' htmlFor="select_empresa">Empresa</label>
                                <div className='col-sm-9 col-lg-10'>
                                    <select className='form-select' data-label='empresa' name='select_empresa' id='select_empresa'>
                                        <option hidden value={''}>Seleccione</option>
                                        {
                                            catalogos.empresas.map(empresa => 
                                            <option key={`key_option_empresas_${empresa.ID}`} value={empresa.ID}>{empresa.NOMBRE}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="select_tipo_suc">Tipo de sucursal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <SelectFetch
                                            name={'select_tipo_suc'}
                                            id={'select_tipo_suc'}
                                            ruta='rt_ctg_tipo_suc'
                                            label={'Tipo de sucursal'}
                                            clave_cps={catalogos?.tipo_suc ? (catalogos?.tipo_suc.clave + '-' + catalogos?.tipo_suc.label) : ''}
                                            value={selectedValue ? { value: `${selectedValue.value}`, label: `${selectedValue.label}` } : ''}
                                            onValueChange={(value) => selectedType(value)}
                                        />
                                        {/* <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="tipo_suc"
                                            placeholder="Seleccione un tipo de sucursal"
                                            id="select_tipo_suc"
                                            name="select_tipo_suc"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.tipo_suc}
                                            onChange={(e) => selectedType(e)}
                                        /> */}
                                    </div>
                                    <span className="text-danger txtVacios select_tipo_suc" style={{ display: 'none' }}><strong>Falta completar (Tipo de sucursal)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_nombre">Nombre<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_nombre' id='input_nombre' type="text" data-label="" placeholder='Nombre de la sucursal' />
                                    </div>
                                    <span className="text-danger txtVacios input_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre de la sucursal)</strong></span>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label" htmlFor="input_correo">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_correo' id='input_correo' type="email" placeholder="Correo de contacto" maxLength={80} />
                                    </div>
                                    <span className="text-danger txtVacios input_correo" style={{ display: 'none' }}><strong>Falta completar (Correo de contacto)</strong></span>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label" htmlFor="input_telefono">Teléfono<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_telefono' id='input_telefono' type="text" placeholder="Teléfono de contacto" onChange={validarNumeros} maxLength={10} />
                                    </div>
                                    <span className="text-danger txtVacios input_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono de contacto)</strong></span>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label" htmlFor="input_celular">Celular de contacto<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_celular' id='input_celular' type="text" placeholder="Celular (Whatsapp)" onChange={validarNumeros} maxLength={10} />
                                    </div>
                                    <span className="text-danger txtVacios input_celular" style={{ display: 'none' }}><strong>Falta completar (Celular (Whatsapp))</strong></span>
                                </div>
                            </div>
                            <div className="form-group row" id='form_address' hidden>
                                <div className='col-md-12 mb-4'>
                                    <label className="col-form-label" htmlFor="input_buscar">Buscar dirección<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_buscar' id='input_buscar' type="text" data-label="Buscar dirección" placeholder="Ingresa una ubicación"/>
                                    </div>
                                    <span className="text-danger txtVacios input_buscar" style={{ display: 'none' }}><strong>Falta completar (Buscar dirección)</strong></span>
                                    </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_calle">Calle<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_calle' id='input_calle' type="text" data-label="" placeholder='Calle' maxLength={50} />
                                    </div>
                                    <span className="text-danger txtVacios input_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_ext">Número exterior<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_ext' id='input_num_ext' type="text" data-label="" placeholder='Numero exterior' />
                                    </div>
                                    <span className="text-danger txtVacios input_num_ext" style={{ display: 'none' }}><strong>Falta completar (Numero exterior)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_num_int">Número interior</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_num_int' id='input_num_int' type="text" data-label="" placeholder='Numero interior' />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_colonia">Colonia<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_colonia' id='input_colonia' type="text" data-label="" placeholder='Colonia' />
                                    </div>
                                    <span className="text-danger txtVacios input_colonia" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_cp">Código postal<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_cp' id='input_cp' type="text" data-label="" placeholder='Código postal' maxLength={5}
                                            onInput={(e) => Tools.formatoNumber(e)}/>
                                    </div>
                                    <span className="text-danger txtVacios input_cp" style={{ display: 'none' }}><strong>Falta completar (Código postal)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_estado">Estado<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="estado"
                                            placeholder="Seleccione un Estado"
                                            id="select_estado"
                                            name="select_estado"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.estados}
                                            onChange={(e) => { changeState(e.e.target.value) }}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios select_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='col-form-label' htmlFor="select_ciudad">Ciudad<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="ciudad"
                                            placeholder="Seleccione una Ciudad"
                                            id="select_ciudad"
                                            name="select_ciudad"
                                            tipoValue={1}
                                            value={''}
                                            datos={catalogos.ciudades}
                                            onChange={() => null}
                                        />
                                    </div>
                                    <span className="text-danger txtVacios select_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className="col-form-label" htmlFor="input_referencias">Referencias de dirección</label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_referencias' id='input_referencias' type="text" data-label="" placeholder='Referencias' maxLength={100}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_latitud">Latitud<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_latitud' id='input_latitud' type="text" data-label="" placeholder='Latitud' maxLength={40}
                                            onInput={(e) => Tools.formatoCoordenadas(e)}/>
                                    </div>
                                    <span className="text-danger txtVacios input_latitud" style={{ display: 'none' }}><strong>Falta completar (Latitud)</strong></span>
                                </div>
                                <div className='col-md-3 mb-4'>
                                    <label className="col-form-label" htmlFor="input_longitud">Longitud<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_longitud' id='input_longitud' type="text" data-label="" placeholder='Longitud' maxLength={40}
                                            onInput={(e) => Tools.formatoCoordenadas(e)} />
                                    </div>
                                    <span className="text-danger txtVacios input_longitud" style={{ display: 'none' }}><strong>Falta completar (Longitud)</strong></span>
                                </div>
                            </div>
                            <button className='btn btn-purple' type='button' onClick={enviarDatosFormulario}>Guardar sucursal</button>
                        </form>
                    </div>
                </div>
            </div>

        </Fragment>)
}



export default Default;