import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';
import Table from '../common/tabla'
import { Briefcase } from 'react-feather';

import { Box } from 'react-feather';


const Default = ({
    esRefresh, setEsRefresh
}) => {
    const navigate = useNavigate()
    const [esRefreshLoc, setEsRefreshLoc] = useState(true)
    const [filtros, setFiltros] = useState({
        origen: '',
    })

    const [catalogos, setCatalogos] = useState({
        ctgSuc: [],
        ctgTipos: [],
        ctgEstatusRuta: [],
        ctgConductor: [],


    })

    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: '' }))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({ ...s }))
    }

    const onSelectOrigen = (datos) => {
        setFiltros((s) => ({ ...s, origen: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectDestino = (datos) => {
        setFiltros((s) => ({ ...s, destino: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectEstatus = (datos) => {
        setFiltros((s) => ({ ...s, estatus: datos}))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectTipos = (datos) => {
        setFiltros((s) => ({ ...s, tipo: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }
    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s, fecha: datos }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectConductor = (datos) => {
        setFiltros((s) => ({ ...s, conductor: datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const colorSelector = (val) => {
        switch (val) {
            case 0:
                return '#dc3545'
            case 1:
                return ''
            case 2:
                return '#ffc107'
            case 3:
                return '#fd7e14'
            case 4:
                return '#17a2b8'
            case 5:
                return '#28a745'
            default:
                break;
        }
    }


    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_suc',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_estatus_ruta',
            method: 'POST',
            body: {}
        }, (response) => {
            console.log(12313, response.datos.ctgEstatus)
            setCatalogos((s) => ({ ...s, ctgEstatus: response.datos.ctgEstatus }))
        })


        tools.toolFetch({
            ruta: 'rt_ctg_tipo_ruta',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgTipos: response.datos.ctgTipos }))

        })

        tools.toolFetch({
            ruta: 'rt_ctg_conductor',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgConductor: response.datos.ctgConductores }))
        })

    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])



    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_EM}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_empresas_eliminar',
                    method: 'POST',
                    body: { id: d.ID_EM },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = falses
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    return <Fragment>
        <Breadcrumb icon={Briefcase} title={'Rutas'} parent={['rutas']} />
        <div className="card">
            <div className="col-xl-12 col-md-12">
                {/* <div className="card-header d-flex justify-content-center p-3">
                    <h4><strong>EMPRESAS PRINCIPALES</strong></h4>
                </div> */}

                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                        <Fragment>
                            <FilterRemove
                                className={'m-1'}
                                style={{ cursor: 'pointer', fontSize: 14 }}
                                onClick={() => limpiarFiltro()}
                            />
                            <Filtros
                                title={<Fragment>
                                    <Sliders size={14} /> Filtros</Fragment>}
                                items={[
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar por origen</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Sucursal"
                                                    placeholder="Seleccionar origen"
                                                    id="list_suc"
                                                    name="list_suc"
                                                    tipoValue={1}
                                                    value={filtros.origen ? filtros.origen : ''}
                                                    datos={catalogos.ctgSuc}
                                                    onChange={onSelectOrigen}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label className="form-label">Filtrar por destino</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Sucursal"
                                                    placeholder="Seleccionar destino"
                                                    id="list_suc"
                                                    name="list_suc"
                                                    tipoValue={1}
                                                    value={filtros.destino ? filtros.destino : ''}
                                                    datos={catalogos.ctgSuc}
                                                    onChange={onSelectDestino}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por estatus</label>
                                                {/* <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar estatus"
                                                    id="list_est"
                                                    name="list_est"
                                                    tipoValue={0}
                                                    value={filtros.estatus ? filtros.estatus : ''}
                                                    datos={catalogos.ctgEstatus}
                                                    onChange={onSelectEstatus}
                                                /> */}


                                                <select
                                                    className="form-control form-control-md border"
                                                    data-label="Sucursal"
                                                    id="list_est"
                                                    name="list_est"
                                                    value={filtros.estatus ? filtros.estatus : ''}
                                                    onChange={(e) => {
                                                        onSelectEstatus(e.target.value)
                                                    }}
                                                >
                                                    <option value={''} defaultValue>Seleccionar estatus</option>
                                                    {catalogos.ctgEstatus && catalogos.ctgEstatus.length > 0 ? (
                                                        catalogos.ctgEstatus.map((estatus) => (
                                                            <option key={estatus.clave} value={estatus.value}>
                                                                {estatus.label}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option disabled>Cargando estatus...</option>
                                                    )}
                                                </select>
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por tipo</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar tipos"
                                                    id="list_est"
                                                    name="list_est"
                                                    tipoValue={1}
                                                    value={filtros.tipo ? filtros.tipo : ''}
                                                    datos={catalogos.ctgTipos}
                                                    onChange={onSelectTipos}
                                                />


                                            </div>
                                    },
                                    {
                                        element:
                                            <div style={{ width: 300 }}>
                                                <label htmlFor="buscar">
                                                    Filtrar por fecha
                                                </label>
                                                <input
                                                    id="fecha"
                                                    name="fecha"
                                                    type="date"
                                                    className="form-control"
                                                    value={filtros.fecha ? filtros.fecha : ''}
                                                    onChange={(e) => {
                                                        onSelectFecha(e.target.value)
                                                    }}
                                                />
                                            </div>
                                    },
                                    {
                                        element:
                                            <div>
                                                <label className="form-label">Filtrar por conductor</label>
                                                <Select
                                                    className="form-control form-control-md border border-dark"
                                                    data-label="Entrada"
                                                    placeholder="Seleccionar conductor"
                                                    id="list_est"
                                                    name="list_est"
                                                    tipoValue={1}
                                                    value={filtros.conductor ? filtros.conductor : ''}
                                                    datos={catalogos.ctgConductor}
                                                    onChange={onSelectConductor}
                                                />
                                            </div>
                                    },

                                ]}
                            />
                        </Fragment>
                    </div>
                    < div className="mt-3" >
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <button
                                    className="btn btn-purple btn-nuevo"
                                    type="button"
                                    onClick={() => {
                                        navigate('/rutas/nueva')
                                    }}
                                > Nueva ruta
                                </button>}
                            headers={[
                                {
                                    nombre: 'ID', tipo: 'html', texto: 'ID_RUTA', html: (d) => {
                                        return <div>{d.ID_RUTA}</div>
                                    }
                                },
                                {
                                    nombre: 'FECHA', tipo: 'html', texto: 'FECHA', html: (d) => {
                                        return <div>{d.FECHA}</div>
                                    }
                                },
                                {
                                    nombre: 'ORIGEN', tipo: 'html', texto: 'ORIGEN', html: (d) => {
                                        return <div>{d.ORIGEN}</div>
                                    }
                                },
                                {
                                    nombre: 'DESTINO', tipo: 'html', texto: 'DESTINO', html: (d) => {
                                        return <div>{d.DESTINO}</div>
                                    }
                                },
                                {
                                    nombre: 'CONDUCTOR', tipo: 'html', texto: 'CONDUCTOR', html: (d) => {
                                        return <div>{d.CONDUCTOR} </div>
                                    }
                                },
                                {
                                    nombre: 'TIPO', tipo: 'html', texto: 'NOMBRE_TP', html: (d) => {
                                        return <div>Ruta {d.NOMBRE_TP}</div>
                                    }
                                },
                                {
                                    nombre: 'ESTATUS', tipo: 'html', texto: 'NOMBRE_EST', html: (d) => {
                                        return <>
                                            <div>
                                                <div className='row'>
                                                    {d.NOMBRE_EST != '' || d.NOMBRE_EST != null ?
                                                        <div className='col-md-6 p-0'>
                                                            <div className="card border-secondary mb-1 text-center">
                                                                <div className="card-body text-secondary p-0">
                                                                    <strong className="card-title p-0 m-0" style={{ fontSize: 14, color: colorSelector(d.ESTATUS_RUTA) }}>{d.NOMBRE_EST}</strong><br />
                                                                    {/* <p className="card-text" style={{fontSize: 12}}>{d.FECHA_ALTA_SE_TEXT}</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-6 p-0 text-center'>
                                                            Sin estatus
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                },
                                {
                                    nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(`/rutas/${d.ID_RUTA}`)
                                                }}
                                            />
                                            <BtnEliminar
                                                onClick={() => onClickEliminar(d)}
                                            />
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'}
                            path={'/rt_rutas'}
                            refresh={esRefreshLoc}
                            body={{ ...filtros }}
                            rowsPerPage={10}
                            paginadorTop={true}
                        >
                        </Table>
                    </div >
                </div>


            </div>
        </div>
    </Fragment>
}

export default Default


