import React, { Fragment } from 'react'
import { NumericFormat } from 'react-number-format';
import { User, Mail, Phone, Smartphone, ChevronRight } from 'react-feather'
import Tools from '../../../js/tools'
import notie from 'notie'

const Default = ({
    state,
    setState,
    formEmpresa = {},
    setFormEmpresa,
    disabledInputs
}) => {
    const verificarCliente = () => {
        const _formEmpresa = Tools.getForm('formEmpresa', { claveEtiquetas: '.txtVacios' })

        if (_formEmpresa.formIsEmpty) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_formEmpresa.stringIsEmpty})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_clientes_verifica',
            method: 'POST',
            body: {
                form: _formEmpresa.data,

            },
        }, (response) => {
            console.log(response)
            if (response.estatus == 'existe-cliente') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El cliente ya existe intente con otro nombre de empresa`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus == 'cel-invalido') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El número de celular debe contener 10 dígitos`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus == 'tel-invalido') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El número de teléfono debe contener 10 dígitos`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            if (response.estatus == 'correo-invalido') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `El correo ingresado no es válido`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
           

            setFormEmpresa(_formEmpresa.data)
            setState((s) => ({
                ...s,
                siguienteFormulario: 2
            }))
        })
    }

    return (
        <Fragment>
            <div className="col-12 ">
                <div className="container-fluid card">
                    <form className="row needs-validation" id='formEmpresa'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className="card-header d-flex justify-content-center">
                                <h4><strong>DATOS DEL CLIENTE</strong></h4>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            {/* <div className="card"> */}
                            <div className="card-body pb-0">
                                {/* <div className="job-description">
                                <div className={`form theme-form needs-validation tooltip-validation ${this.validateClass ? 'validateClass' : ''}`} noValidate="" > */}
                                {/* <h6 className="mb-3"><strong>DATOS DEL CLIENTE</strong></h6> */}
                                <div className={`form theme-form needs-validation tooltip-validation form-row`}>
                                    <div className="col-md-6">
                                        <label htmlFor="validationTooltip01">Empresa (Nombre Comercial)<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            <input
                                                className="form-control"
                                                id="txt_nombre_comercial"
                                                name="txt_nombre_comercial"
                                                autoComplete='off'
                                                type="text"
                                                placeholder="Empresa"
                                                defaultValue={formEmpresa ? formEmpresa.txt_nombre_comercial : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                                maxLength={50}
                                            />
                                        </div>

                                        <span className="text-danger txtVacios txt_nombre_comercial" style={{ display: 'none' }}><strong>Falta completar (Empresa)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre de Contacto<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                            <input className="form-control" id="txt_contacto" name="txt_contacto" type="text"
                                                autoComplete='off'
                                                placeholder="Nombre de Contacto"
                                                defaultValue={formEmpresa ? formEmpresa.txt_contacto : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                                onInput={(e) => Tools.validName(e)}
                                                maxLength={50}
                                            />
                                            <span className="text-danger txtVacios txt_contacto" style={{ display: 'none' }}><strong>Falta completar (Nombre de Contacto)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Apellidos de Contacto<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><User /></span></div> */}
                                            <input className="form-control" id="txt_apellidos" name="txt_apellidos" type="text"
                                                autoComplete='off'
                                                placeholder="Apellidos de Contacto"
                                                defaultValue={formEmpresa ? formEmpresa.txt_apellidos : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                                onInput={(e) => Tools.validName(e)}
                                                maxLength={50}
                                            />
                                            <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos de Contacto)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustomUsername">Correo Electrónico<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div> */}
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="correo_empresa"
                                                name="correo_empresa"
                                                type="email"
                                                placeholder="Correo Electrónico"
                                                defaultValue={formEmpresa ? formEmpresa.correo_empresa : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                                maxLength={50}
                                            />
                                            <span className="text-danger txtVacios correo_empresa" style={{ display: 'none' }}><strong>Falta completar (Correo Electrónico)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="validationTooltip02">Teléfono<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            {/* <div className="input-group-prepend"><span className="input-group-text"><Phone /></span></div> */}
                                            {/* <NumericFormat format="(###) ###-####" mask="_" className="form-control"
                                                id="tel_empresa" name="tel_empresa"
                                                autoComplete='off'
                                                placeholder=''
                                                defaultValue={formEmpresa ? formEmpresa. : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                            /> */}
                                            <input className="form-control"
                                                id="tel_empresa" name="tel_empresa"
                                                placeholder='Teléfono'
                                                maxLength={10}
                                                autoComplete='off'
                                                onInput={(e) => Tools.formatoNumber(e)}
                                                defaultValue={formEmpresa ? formEmpresa.tel_empresa : ''}
                                            />
                                            <span className="text-danger txtVacios tel_empresa" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="validationCustomUsername">Celular<span className="text-danger">*</span></label>
                                        <div className="input-group input-gpack2">
                                            {/* <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Smartphone /></span></div> */}
                                            {/* <NumericFormat format="(###) ###-####" mask="_" className="form-control"
                                                id="cel_empresa" name="cel_empresa"
                                                placeholder='Celular'
                                                autoComplete='off'
                                                defaultValue={formEmpresa ? formEmpresa.cel_empresa : ''}
                                                disabled={disabledInputs ? disabledInputs : false}
                                            /> */}
                                            <input className="form-control"
                                                id="cel_empresa" name="cel_empresa"
                                                placeholder='Celular'
                                                maxLength={10}
                                                autoComplete='off'
                                                onInput={(e) => Tools.formatoNumber(e)}
                                                defaultValue={formEmpresa ? formEmpresa.cel_empresa : ''}
                                            />

                                            <span className="text-danger txtVacios cel_empresa" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {disabledInputs === undefined ? <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className='card-footer'>
                        <div className='row'>
                            <div className="col-md-12 d-flex justify-content-end">
                                <button className="btn btn-purple d-flex align-items-center" type="button"
                                    onClick={() => verificarCliente()} >Siguiente <ChevronRight /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </Fragment>)
}

export default Default