import React, { Fragment, useState, useEffect } from 'react';
import { UserPlus } from 'react-feather';
import notie from 'notie';
import Tools from '../../js/tools';
import Breadcrumb from '../common/breadcrumbv2';

const Default = (props) => {
    /* ==================== HOOKS ==================== */
    const [catalogos, setCatalogos] = useState({
        empresas: [],
        tipos: [],
        permisosSecciones: []
    });

    /* ==================== CONSTANTES ==================== */

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {
        fetch('../rt_catalogos_agregar_usuario', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'}
        }).then(function(res) { return res.json(); })
        .then(function(res) {
            console.log('res', res);
            if(res.estatus === 'OK'){
                let seccionesPermisos = groupArrayByKey(res.datos.permisos, 'id_sec', 'id');

                let sec = res.datos.secciones.map(seccion => {
                    return {
                        id: seccion.id,
                        titulo: seccion.nombre,
                        permisos: seccionesPermisos[seccion.id].array
                    }
                });

                res.datos.permisosSecciones = sec;
                res.datos.secciones = null;

                setCatalogos(res.datos);
                console.log('res.datos', res.datos);
            }

            else
                throw Error(res.estatus);
        }).catch(function(err){
            notie.alert({text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5});
            console.log('err', err);
        })
    }, [])


    /* ==================== FUNCIONES ==================== */
    const groupArrayByKey = (xs, key, param) => {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || {key: x[param], array: []}).array.push(x);
          return rv;
        }, {});
    }

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('form_usuario', {ignore: ['input_CELULAR']});
        console.log('datos formulario', datos);

        if(datos.formIsEmpty > 0){
            notie.alert({text: `El campo ${datos.stringIsEmpty} es requerido`, type: 3, time: 4});
            return;
        }
        if (!Tools.validMail(datos.data.input_CORREO)) {
            document.querySelector(`.input_CORREO`).style['display'] = 'block'
            notie.alert({text: `Ingresa un correo válido.`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_TELEFONO.length < 10){
            document.querySelector(`.input_TELEFONO`).style['display'] = 'block'
            notie.alert({text: `El campo Teléfono debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }
        if(datos.data.input_CELULAR.length < 10){
            document.querySelector(`.input_CELULAR`).style['display'] = 'block'
            notie.alert({text: `El campo Celular debe de tener al menos 10 caracteres`, type: 3, time: 4});
            return;
        }

        let form_permisos = new FormData(document.getElementById('form_permisos'));
        let permisos_form = [], permiso_split, e;
        for(e of form_permisos.entries()){
            permiso_split = e[0].split('_');
            permisos_form.push({
                id: permiso_split[2]
            });
        };

        datos.data['permisos'] = JSON.stringify(permisos_form);
/*         let body = {}

        Object.keys(datos.data).foreach(key => {
            body[key.replace(/input_/g, '')] = datos.data[key];
        }) */

        fetch('../rt_agregar_usuario_permisos', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .then(res => {
            if(res.estatus === 'OK'){
                if(res.datos.ESTATUS === 'AGREGADO'){
                    notie.alert({text: 'Usuario creado correctamente', type: 1, time: 5});
                }else{
                    notie.alert({text: 'El usuario ya se encuentra registrado', type: 2, time: 5});
                }
            }else {
                throw Error(res.estatus)
            }
        }).catch(err => {
            notie.alert({text: 'No se pudo agregar el usuario, inténtelo más tarde', type: 3, time: 6});
            console.log(err);
        })
    }

    const ComponentePermisos = (propsPermisos) => {
        console.log('propsPermisos', propsPermisos);
        return (
            <Fragment>
                <div className='card-permisos'>
                    <div><h5>{propsPermisos.titulo}</h5></div>
                    <hr className='solid'></hr>
                        {
                            propsPermisos.permisos.map((permiso) => {
                                let id = `input_${propsPermisos.id}_${permiso.id}`;

                                return <div key={`key_permiso_${permiso.id}`} className="checkbox">
                                    <input id={id} name={id} type="checkbox" value={'1'}/>
                                    <label htmlFor={id}>{permiso.nombre}</label>
                                </div>
                            }
                        )}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Breadcrumb icon={UserPlus} title={'Nuevo usuario'} parent={['usuarios-cms']}/>

            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_usuario' className="theme-form">

                            <div className='form-group row'>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className='col-form-label' htmlFor="input_EMPRESA">Empresa<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <select className='form-select' data-label='Empresa' name='input_EMPRESA' id='input_EMPRESA'>
                                            <option hidden value={''}>Seleccione una empresa</option>
                                            {
                                                catalogos.empresas.map(item => 
                                                <option key={`key_option_empresas_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <span className="text-danger txtVacios input_EMPRESA" style={{display: 'none'}}><strong>Falta completar (Empresa)</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_NOMBRES">Nombre (s)<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_NOMBRES' id='input_NOMBRES' type="text" data-label="Nombre (s)" placeholder='Nombre (s)' maxLength={50}/>
                                    </div>
                                    <span className="text-danger txtVacios input_NOMBRES" style={{display: 'none'}}><strong>Falta completar (Nombre (s))</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_APELLIDOS">Apellidos<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_APELLIDOS' id='input_APELLIDOS' type="text" data-label="Apellidos" placeholder='Apellidos' maxLength={50}/>
                                    </div>
                                    <span className="text-danger txtVacios input_APELLIDOS" style={{display: 'none'}}><strong>Falta completar (Apellidos)</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_CORREO">Correo<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_CORREO' id='input_CORREO' type="text" data-label="Correo" placeholder='Correo' maxLength={50}/>
                                    </div>
                                    <span className="text-danger txtVacios input_CORREO" style={{display: 'none'}}><strong>Falta completar (Correo)</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_TELEFONO">Teléfono<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_TELEFONO' id='input_TELEFONO' type="text" data-label="Teléfono" placeholder='Teléfono' maxLength={10} onInput={(e) => Tools.formatoNumber(e)}/>
                                    </div>
                                    <span className="text-danger txtVacios input_TELEFONO" style={{display: 'none'}}><strong>Falta completar (Teléfono)</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className="col-form-label" htmlFor="input_CELULAR">Celular<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <input className="form-control" name='input_CELULAR' id='input_CELULAR' type="text" data-label="Celular" placeholder='Celular' maxLength={10} onInput={(e) => Tools.formatoNumber(e)}/>
                                    </div>
                                    <span className="text-danger txtVacios input_CELULAR" style={{display: 'none'}}><strong>Falta completar (Celular)</strong></span>
                                </div>
                                <div className='col-md-6 col-12 mb-3'>
                                    <label className='col-form-label' htmlFor="input_TIPO">Tipo usuario<span className="text-danger">*</span></label>
                                    <div className="input-group input-gpack2">
                                        <select className='form-select' data-label='Tipo de usuario' name='input_TIPO' id='input_TIPO'>
                                        <option hidden value={''}>Seleccione un tipo de usuario</option>
                                        {
                                            catalogos.tipos.map(item => 
                                            <option key={`key_option_tipos_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                            )
                                        }
                                        </select>
                                    </div>
                                    <span className="text-danger txtVacios input_TIPO" style={{display: 'none'}}><strong>Falta completar (Tipo usuario)</strong></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="container-fluid d-print-none">
                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <div className="page-header-left">
                                    <h3>Asignar permisos</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <form id='form_permisos' className="theme-form">
                            <div className='row'>
                            {
                                catalogos.permisosSecciones.map(seccion => {
                                    return (
                                        <div key={`key_seccion_${seccion.id}`} className='col-12 col-md-6'>
                                            <ComponentePermisos 
                                                titulo = {seccion.titulo}
                                                id = {seccion.id}
                                                permisos = {seccion.permisos}
                                            />
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div className='row mt-4'>
                                <div className='col-12 text-center'>
                                    <button className='btn btn-purple' type='button' onClick={enviarDatosFormulario}>Guardar registro de usuario</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;