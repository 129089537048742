import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnPDF } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';
import SelectFetch from '../common/select-fetch';
import tools from '../../js/tools';


const Default = (
    { esRefresh, setEsRefresh }
) => {
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const navigate = useNavigate()
    const [filtros, setFiltros] = useState({
        suc: '',
    })

    const [catalogos, setCatalogos] = useState({
        ctgSuc: [],
    })


    
    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: ''}))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({...s}))
    }


    const onSelectSuc = (datos) => {
        setFiltros((s) => ({ ...s ,suc : datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s , fecha : datos}))
        setEsRefreshLoc((s) => !s)
    }

     function descargarReporteApertura(folio, sucursal) {
            notie.alert({
                type: 'success',
                text: `Descargando archivo, esto puede tardar unos momentos.`,
                time: 4,
                position: 'top'
            })
            fetch('rt_descargar_reporte_apertura_caja_pdf', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({ folio, sucursal })
            })
                .then((response) => response.blob())
                .then(async (response) => {
                    if (response.estatus == 'ERROR-DESCARGA') {
                        return notie.alert({
                            type: 'error',
                            text: `Ha ocurrido un error al intentar descargar el archivo que estás buscando.`,
                            time: 3,
                            position: 'top'
                        })
                    }
                    const url = window.URL.createObjectURL(response)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = `Reporte-apertura-caja-${folio}.pdf`
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
        }
    

    useEffect(() => {
            tools.toolFetch({
                ruta: 'rt_ctg_suc',
                method: 'POST',
                body: {}
            }, (response) => {
                setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
            })
    
           
        
        }, [])
    
        useEffect(() => {
            setEsRefreshLoc((s) => !s)
        }, [esRefresh])


    // const eliminar = (data) => {
    //     Tools.toolFetch({
    //         ruta: 'rt_prod_serv_eliminar',
    //         method: 'POST',
    //         body: data,
    //     }, (response) => {
    //         console.log(response)
    //         if (response.estatus === 'ERROR') {
    //             return notie.alert({
    //                 type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //                 text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
    //                 // stay: Boolean, // optional, default = false
    //                 time: 2, // optional, default = 3, minimum = 1,
    //                 position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //             })
    //         }

    //         setEsRefresh((s) => !s)
    //         notie.alert({
    //             type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //             text: `Se ha eliminado este registro.`,
    //             // stay: Boolean, // optional, default = false
    //             time: 2, // optional, default = 3, minimum = 1,
    //             position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
    //         })
    //     })
    // }

    return (
        <Fragment>
            <Breadcrumb icon={Clipboard} title={'Apertura de caja'} parent={['apertura-caja']} />

            <div className="card" >
                <div className="card-body">
                    <div className='row'>

                        <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                            <Fragment>
                                <FilterRemove
                                    className={'m-1'}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                    onClick={() => limpiarFiltro()}
                                />
                                <Filtros
                                    title={<Fragment>
                                        <Sliders size={14} /> Filtros</Fragment>}
                                    items={[
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar Sucursal</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Sucursal"
                                                        placeholder="Seleccionar Sucursal"
                                                        id="list_edo"
                                                        name="list_edo"
                                                        tipoValue={1}
                                                        value={filtros.suc ? filtros.suc : ''}
                                                        datos={catalogos.ctgSuc}
                                                        onChange={onSelectSuc}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label htmlFor="buscar">
                                                        Filtrar por fecha
                                                    </label>
                                                    <input
                                                        id="fecha"
                                                        name="fecha"
                                                        type="date"
                                                        className="form-control"
                                                        value={filtros.fecha ? filtros.fecha : ''}
                                                        onChange={(e) => {
                                                            onSelectFecha(e.target.value)
                                                        }}                                      
                                                    />
                                                </div>
                                        }
                                    ]}
                                />
                            </Fragment>
                        </div>
                        <div className="col-xl-12">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'FOLIO', tipo: 'html', texto: 'FOLIO', html: (d) => {
                                            return <div>{d.FOLIO}</div>
                                        }
                                    },
                                    {
                                        nombre: 'RESPONSABLE', tipo: 'html', texto: 'RESPONSABLE', html: (d) => {
                                            return <div>{d.RESPONSABLE}</div>
                                        }
                                    },
                                    {
                                        nombre: 'FECHA_ARQUEO', tipo: 'html', texto: 'FECHA_ARQUEO', html: (d) => {
                                            return <div>{d.FECHA_ARQUEO}</div>
                                        }
                                    },
                                    {
                                        nombre: 'TOTAL', tipo: 'html', texto: 'TOTAL', html: (d) => {
                                            return <div>{d.TOTAL}</div>
                                        }
                                    },
                                    {
                                        nombre: 'CAJA', tipo: 'html', texto: 'CAJA', html: (d) => {
                                            return <div>{d.CAJA}</div>
                                        }
                                    },
                                    {
                                        nombre: 'SUCURSAL', tipo: 'html', texto: 'SUCURSAL', html: (d) => {
                                            return <div>{d.NOMBRE_SUC}</div>
                                        }
                                    },
                                    // {
                                    //     nombre: 'SUCURSAL', tipo: 'html', texto: 'SUCURSAL_TEXT', html: (d) => {
                                    //         return <div>{d.SUCURSAL_TEXT}</div>
                                    //     }
                                    // },
                                    {
                                        nombre: 'REPORTE', tipo: 'html', texto: 'Reporte',
                                        html: (d) => {
                                            return <div>
                                                
                                                <BtnPDF
                                                    onClick={() => descargarReporteApertura(d.FOLIO, d.ID_SUC_ARQ)}
                                                />

                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_aperturas'}
                                refresh={esRefreshLoc}
                                body={{...filtros}}
                                rowsPerPage={10}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;