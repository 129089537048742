import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Eye, Trash2, Check, X, Edit, ArrowLeft, EyeOff} from 'react-feather';
let size = 25

const BtnEliminar = (props) => {
    return <button type="button" className={`btn btn-outline-danger btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2}}
        disabled={props.disabled}
        onClick={props.onClick}><Trash2 size={size}/></button>
}

const BtnEditar = (props) => {
    return <button type="button" className={`btn btn-outline-success btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2}}
        disabled={props.disabled}
        onClick={props.onClick}><Edit size={size}/></button>
}

const BtnAccion = (props) => {
    return <button type="button" className={`btn btn-outline-warning btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2}}
        disabled={props.disabled}
        onClick={props.onClick}><Edit size={size}/></button>
}

const BtnVer = (props) => {
    return <button type="button" className={`btn btn-outline-success btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2}}
        disabled={props.disabled}
        onClick={props.onClick}><Eye size={size}/></button>
}

const BtnNoVer = (props) => {
    return <button type="button" className={`btn btn-outline-danger btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2}}
        disabled={props.disabled}
        onClick={props.onClick}><EyeOff size={size}/></button>
}

const BtnAtras = (props) => {
    return <button 
        type="button" 
        className={`btn btn-outline-dark mr-3`} 
        style={{ margin: 2 }}
        disabled={props.disabled}
        onClick={props.onClick}>
        <ArrowLeft />
    </button>
}

const BtnPDF = (props) => {
    return <button type="button" className={`btn btn-outline-danger btn-sm pl-3 pr-3 pt-1 pb-0`} style={{margin: 2, fontSize: 20}}
        disabled={props.disabled}
        onClick={props.onClick}><i className="fa fa-file-pdf-o"></i></button>
}

export  {
    BtnEliminar,
    BtnEditar,
    BtnVer,
    BtnAtras,
    BtnNoVer,
    BtnPDF,
    BtnAccion
}