import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import tools from '../../js/tools';
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Clipboard } from 'react-feather';
import Filtros from '../common/dropdown_simple';
import { FilterRemove } from '../common/button-icons';
import { Sliders } from 'react-feather';
import Select from '../common/select-basic';

const Default = (
    {esRefresh, setEsRefresh}
    
) => {
    // const [esRefresh, setEsRefresh] = useState(false)
    const [esRefreshLoc, setEsRefreshLoc] = useState(false)
    const navigate = useNavigate()
    const [filtros, setFiltros] = useState({
        suc : '',
    })
    const [catalogos, setCatalogos] = useState({
            ctgSuc: [],
            ctgEstatus: [],
            ctgEstatusPago: [],
            
        })

    const limpiarFiltro = () => {
        setFiltros((s) => ({ suc: ''}))
        setEsRefreshLoc((s) => !s)
        setCatalogos((s) => ({...s}))
    }


    const onSelectSuc = (datos) => {
        setFiltros((s) => ({ ...s ,suc : datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectEstatus = (datos) => {
        setFiltros((s) => ({ ...s ,estatus : datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectEstatusPago = (datos) => {
        setFiltros((s) => ({ ...s , estatusPago : datos.item.clave }))
        setEsRefreshLoc((s) => !s)
    }

    const onSelectFecha = (datos) => {
        setFiltros((s) => ({ ...s , fecha : datos}))
        setEsRefreshLoc((s) => !s)
    }

 


    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_prod_serv_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }


    useEffect(() => {
        tools.toolFetch({
            ruta: 'rt_ctg_suc_todas',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgSuc: response.datos.ctgSuc }))
        })

        tools.toolFetch({
            ruta: 'rt_ctg_estatus',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgEstatus: response.datos.ctgEstatus }))
        })

        
        tools.toolFetch({
            ruta: 'rt_ctg_estatus_pago',
            method: 'POST',
            body: {}
        }, (response) => {
            setCatalogos((s) => ({ ...s, ctgEstatusPago: response.datos.ctgEstatusPago }))
        })
    
    }, [])

    useEffect(() => {
        setEsRefreshLoc((s) => !s)
    }, [esRefresh])

    return (
        <Fragment>
            <Breadcrumb icon={Clipboard} title={'Solicitudes'} parent={['solicitudes']} />
            <div className="card" >
                <div className="card-body">
                    <div className='row'>
                        <div className="col-md-12 d-flex justify-content-end align-items-center filtros-productos">
                            <Fragment>
                                <FilterRemove
                                    className={'m-1'}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                    onClick={() => limpiarFiltro()}
                                />
                                <Filtros
                                    title={<Fragment>
                                        <Sliders size={14} /> Filtros</Fragment>}
                                    items={[
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar Sucursal</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Sucursal"
                                                        placeholder="Seleccionar Sucursal"
                                                        id="list_suc"
                                                        name="list_suc"
                                                        tipoValue={1}
                                                        value={filtros.suc ? filtros.suc : ''}
                                                        datos={catalogos.ctgSuc}
                                                        onChange={onSelectSuc}

                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div>
                                                    <label className="form-label">Filtrar por estatus de solicitud</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Entrada"
                                                        placeholder="Seleccionar estatus de solicitud"
                                                        id="list_est"
                                                        name="list_est"
                                                        tipoValue={1}
                                                        value={filtros.estatus ? filtros.estatus : ''}
                                                        datos={catalogos.ctgEstatus}
                                                        onChange={onSelectEstatus}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label className="form-label">Filtrar por estatus de pago</label>
                                                    <Select
                                                        className="form-control form-control-md border border-dark"
                                                        data-label="Salida"
                                                        placeholder="Seleccionar estatus de pago"
                                                        id="list_est_pago"
                                                        name="list_est_pago"
                                                        tipoValue={1}
                                                        datos={catalogos.ctgEstatusPago}
                                                        value={filtros.estatusPago ? filtros.estatusPago : ''}
                                                        onChange={onSelectEstatusPago}
                                                    />
                                                </div>
                                        },
                                        {
                                            element:
                                                <div style={{ width: 300 }}>
                                                    <label htmlFor="buscar">
                                                        Filtrar por fecha
                                                    </label>
                                                    <input
                                                        id="fecha"
                                                        name="fecha"
                                                        type="date"
                                                        className="form-control"
                                                        value={filtros.fecha ? filtros.fecha : ''}
                                                        onChange={(e) => {
                                                            onSelectFecha(e.target.value)
                                                        }}                                      
                                                    />
                                                </div>
                                        }
                                    ]}
                                />
                            </Fragment>
                        </div>

                        <div className="col-xl-12">
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'SOLICITUD', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                            return <div>#{d.ID_SOL}</div>
                                        }
                                    },
                                    {
                                        nombre: 'CLIENTE', tipo: 'html', texto: 'NOMBRES_CONTACTO_CL', html: (d) => {
                                            return <div>{d.NOMBRES_CONTACTO_CL} {d.APELLIDOS_CONTACTO_CL}</div>
                                        }
                                    },
                                    {
                                        nombre: 'CORREO', tipo: 'html', texto: 'CORREO_CL', html: (d) => {
                                            return <div>{d.CORREO_CL}</div>
                                        }
                                    },
                                    {
                                        nombre: 'TOTAL', tipo: 'html', texto: 'TOTAL_SOL', html: (d) => {
                                            return <div>${d.TOTAL_TEXT}</div>
                                        }
                                    },
                                    {
                                        nombre: 'FECHA ALTA', tipo: 'html', texto: 'FECHA_ALTA_TEXT', html: (d) => {
                                            return <div>{d.FECHA_ALTA_TEXT}</div>
                                        }
                                    },
                                    {
                                        nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/solicitudes/${d.ID_SOL}`)
                                                    }}
                                                />
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_PS}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ id_ps: d.ID_PS })
                                                            },
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_solicitudes'}
                                refresh={esRefreshLoc}
                                body={{...filtros}}
                                rowsPerPage={10}
                                paginadorTop={true}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;